<template>
  <div class="modal-overlay" @click="closeModal">
    <transition name="modal">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h2>Editar Usuario</h2>
          <button class="close-btn" @click="closeModal">×</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="username">Nombre de Usuario</label>
              <input
                id="username"
                type="text"
                v-model="form.username"
                placeholder="Ingresa un nombre de usuario"
                :class="{ error: errors.username }"
              />
              <span v-if="errors.username" class="error-msg">{{ errors.username }}</span>
            </div>

            <div class="form-group">
              <label for="role">Rol</label>
              <select id="role" v-model="form.role" :class="{ error: errors.role }">
                <option value="" disabled>Selecciona un rol</option>
                <option value="admin">Admin</option>
                <option value="agent">Agente</option>
              </select>
              <span v-if="errors.role" class="error-msg">{{ errors.role }}</span>
            </div>

            <div class="modal-footer">
              <button type="submit" class="save-btn">Actualizar</button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        username: this.user.username,
        role: this.user.role,
      },
      errors: {},
    };
  },
  watch: {
    "form.username"(newValue) {
      if (!newValue) {
        this.errors.username = "El nombre de usuario es obligatorio.";
      } else if (newValue.length < 3) {
        this.errors.username = "Debe tener al menos 3 caracteres.";
      } else {
        delete this.errors.username;
      }
    },
    "form.role"(newValue) {
      if (!newValue) {
        this.errors.role = "El rol es obligatorio.";
      } else {
        delete this.errors.role;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async handleSubmit() {
      if (Object.keys(this.errors).length > 0) return;

      try {
        const response = await this.$axios.put(`/users/${this.user.id}`, this.form);
        this.$emit("user-updated", response.data.user);
        this.closeModal();
      } catch (error) {
        if (error.response && error.response.data.error) {
          this.errors = error.response.data.error;
        } else {
          console.error("Error al actualizar usuario:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.modal-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.modal-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  color: #dc3545;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #a71d2a;
  scale: 1.2;
}

.form-group {
  margin-bottom: 15px;
}

.input-container {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.password-toggle:hover {
  color: #0056b3;
}

.password-group input {
  padding-right: 40px; 
}

.form-group input,
.form-group select {
  width: 92%;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #f8f9fa;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group select {
    width: 99%;
}

.form-group input:hover,
.form-group select:hover {
  border-color: #007bff;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 91, 187, 0.3);
}

.form-group input.error,
.form-group select.error {
  border-color: #dc3545;
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
}

.form-group input::placeholder {
  color: #999;
}

.form-group label {
  font-size: 14px;
  font-weight: 600;
  color: #555;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.select-icon {
  position: absolute;
  left: 10px;
  font-size: 18px;
  color: #007bff;
  pointer-events: none;
}

.error-msg {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-btn {
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 0.3s ease, transform 0.2s;
}

.save-btn:hover {
  background: #0056b3;
  transform: scale(1.05);
}

.success-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #28a745;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 2.7s;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-10px); }
}
</style>
