<template>
  <div class="sidebar">
    <ul class="menu">
      <!-- Métricas -->
      <li class="menu-header">Métricas</li>
      <li class="menu-item" :class="{ active: isActive('/dashboard/metrics/messages') }" @click="selectSection">
        <router-link to="/dashboard/metrics/messages">
          <ion-icon name="mail-outline" class="icon"></ion-icon>
          <span class="text">Mensajes</span>
        </router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/dashboard/metrics/contacts') }" @click="selectSection">
        <router-link to="/dashboard/metrics/contacts">
          <ion-icon name="people-outline" class="icon"></ion-icon>
          <span class="text">Contactos</span>
        </router-link>
      </li>

      <li class="divider"></li>

      <!-- Chat -->
      <li class="menu-header">Chat</li>
      <li class="menu-item" :class="{ active: isActive('/dashboard/chat') }" @click="selectSection">
        <router-link to="/dashboard/chat">
          <ion-icon name="chatbubble-outline" class="icon"></ion-icon>
          <span class="text">Chat</span>
        </router-link>
      </li>

      <li class="divider"></li>

      <!-- ChatBot -->
      <li class="menu-header">Bot</li>
      <li class="menu-item" :class="{ active: isActive('/dashboard/chatbot') }" @click="selectSection">
        <router-link to="/dashboard/chatbot">
          <ion-icon name="settings-outline" class="icon"></ion-icon>
          <span class="text">Chatbot</span>
        </router-link>
      </li>

      <li class="divider"></li>

      <!-- Contactos -->
      <li class="menu-header">Clientes</li>
      <li class="menu-item" :class="{ active: isActive('/dashboard/contacts') }" @click="selectSection">
        <router-link to="/dashboard/contacts">
          <ion-icon name="book-outline" class="icon"></ion-icon>
          <span class="text">Clientes</span>
        </router-link>
      </li>

      <li class="divider"></li>

      <!-- Usuarios -->
      <li v-if="userRole !== 'agent'" class="menu-header">Usuarios</li>
      <li v-if="userRole !== 'agent'" class="menu-item" :class="{ active: isActive('/dashboard/users') }" @click="selectSection">
        <router-link to="/dashboard/users">
          <ion-icon name="person-outline" class="icon"></ion-icon>
          <span class="text">Usuarios</span>
        </router-link>
      </li>

      <li class="divider" v-if="userRole !== 'agent'"></li>

      <!-- Líneas -->
      <li v-if="userRole !== 'agent'" class="menu-header">Líneas</li>
      <li v-if="userRole !== 'agent'" class="menu-item" :class="{ active: isActive('/dashboard/lines') }" @click="selectSection">
        <router-link to="/dashboard/lines">
          <ion-icon name="call-outline" class="icon"></ion-icon>
          <span class="text">Líneas</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarSystem",
  data() {
    return {
      userRole: localStorage.getItem("user_role") || "guest", 
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    selectSection() {
      this.$emit("section-selected");
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 200px;          
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-right: 1px solid #e5e7eb;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 8px;          
}

.sidebar::-webkit-scrollbar-track {
  background: #f2f2f2; 
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #c0c0c0; 
  border: 1px solid #999;   
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-header {
  padding: 6px 12px;
  font-size: 10px;                   
  font-weight: 600;
  color: rgba(55, 65, 81, 0.4);     
  text-transform: uppercase;
  text-align: left;                
  margin-top: 7px;
}

.menu-item {
  display: flex;
  align-items: center;
  transition: background 0.2s ease;
  padding: 8px 12px;               
}

.menu-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #374151;
  width: 100%;
  font-size: 14px;                 
}

.menu-item:hover {
  background-color: #dbeafe;
}

.menu-item.active {
  background-color: rgba(59, 130, 246, 0.1);
  border-left: 4px solid #3b82f6;
  font-weight: 600;
}

.menu-item.active a {
  color: #1e3a8a; 
}

.menu-item.active .icon {
  color: #1e40af;
}

.icon {
  font-size: 20px;                  
  margin-right: 6px;
  color: #3b82f6;
}

.divider {
  height: 1px;
  margin: 8px 12px;
  background-color: #e5e7eb;
}

@media (max-width: 768px) {
  .sidebar {
    width: 50px; 
  }

  .menu-header {
    display: none;
  }

  .text {
    display: none;
  }
}

</style>
