<template>
  <div class="modal-overlay" @click="closeModal">
    <transition name="modal">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h2>Crear Usuario</h2>
          <button class="close-btn" @click="closeModal">×</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <!-- Nombre de Usuario -->
            <div class="form-group">
              <label for="username">Nombre de Usuario</label>
              <input
                id="username"
                type="text"
                v-model="form.username"
                placeholder="Ingresa un nombre de usuario"
                :class="{ error: errors.username }"
              />
              <span v-if="errors.username" class="error-msg">{{ errors.username }}</span>
            </div>

            <!-- Contraseña -->
            <div class="form-group password-group">
              <label for="password">Contraseña</label>
              <div class="input-container">
                <input
                  id="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="form.password"
                  placeholder="Ingresa una contraseña"
                  :class="{ error: errors.password }"
                />
                <ion-icon
                  :name="showPassword ? 'eye-off-outline' : 'eye-outline'"
                  class="password-toggle"
                  @click="togglePasswordVisibility"
                ></ion-icon>
              </div>
              <span v-if="errors.password" class="error-msg">{{ errors.password }}</span>
            </div>

            <!-- Confirmar Contraseña -->
            <div class="form-group password-group">
              <label for="password_confirmation">Confirmar Contraseña</label>
              <div class="input-container">
                <input
                  id="password_confirmation"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  v-model="form.password_confirmation"
                  placeholder="Confirma la contraseña"
                  :class="{ error: errors.password_confirmation }"
                />
                <ion-icon
                  :name="showConfirmPassword ? 'eye-off-outline' : 'eye-outline'"
                  class="password-toggle"
                  @click="toggleConfirmPasswordVisibility"
                ></ion-icon>
              </div>
              <span v-if="errors.password_confirmation" class="error-msg">
                {{ errors.password_confirmation }}
              </span>
            </div>

            <!-- Rol -->
            <div class="form-group">
              <label for="role">Rol</label>
              <select id="role" v-model="form.role" :class="{ error: errors.role }">
                <option value="" disabled>Selecciona un rol</option>
                <option value="admin">Admin</option>
                <option value="agent">Agente</option>
              </select>
              <span v-if="errors.role" class="error-msg">{{ errors.role }}</span>
            </div>

            <div class="modal-footer">
              <button type="submit" class="save-btn">Crear</button>
            </div>

            <div v-if="successMessage" class="success-toast">
              {{ successMessage }}
            </div>

          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        password_confirmation: "",
        role: "",
        status: "active", 
      },
      errors: {},
      loading: false,
      successMessage: "",
      showPassword: false, 
      showConfirmPassword: false,
    };
  },
  computed: {
    isFormValid() {
      return (
        this.form.username.length >= 3 &&
        this.form.password.length >= 8 &&
        /[!@#$%^&*(),.?":{}|<>]/.test(this.form.password) &&
        this.form.password === this.form.password_confirmation &&
        this.form.role &&
        Object.keys(this.errors).length === 0
      );
    },
  },
  watch: {
    "form.username"(newValue) {
      if (!newValue) {
        this.errors.username = "El nombre de usuario es obligatorio.";
      } else if (newValue.length < 3) {
        this.errors.username = "El nombre de usuario debe tener al menos 3 caracteres.";
      } else {
        delete this.errors.username;
      }
    },
    "form.password"(newValue) {
      if (!newValue) {
        this.errors.password = "La contraseña es obligatoria.";
      } else if (newValue.length < 8) {
        this.errors.password = "La contraseña debe tener al menos 8 caracteres.";
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newValue)) {
        this.errors.password = "Debe incluir al menos un carácter especial.";
      } else {
        delete this.errors.password;
      }

      if (this.form.password_confirmation && newValue !== this.form.password_confirmation) {
        this.errors.password_confirmation = "Las contraseñas no coinciden.";
      } else {
        delete this.errors.password_confirmation;
      }
    },
    "form.password_confirmation"(newValue) {
      if (!newValue) {
        this.errors.password_confirmation = "Debes confirmar la contraseña.";
      } else if (newValue !== this.form.password) {
        this.errors.password_confirmation = "Las contraseñas no coinciden.";
      } else {
        delete this.errors.password_confirmation;
      }
    },
    "form.role"(newValue) {
      if (!newValue) {
        this.errors.role = "El rol es obligatorio.";
      } else {
        delete this.errors.role;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async handleSubmit() {
      this.errors = {}; 
      this.loading = true; 


      if (Object.keys(this.errors).length > 0) {
        this.loading = false;
        return;
      }

      try {
        const response = await this.$axios.post("/users", this.form);

        if (response.data) {
          this.successMessage = "Usuario creado exitosamente"; 
          setTimeout(() => {
            this.successMessage = "";
            this.closeModal();
          }, 3000); 
          this.$emit("user-created", response.data); 
        }
      } catch (error) {
        if (error.response && error.response.data.error) {
          this.errors = error.response.data.error; 
        } else {
          console.error("Error al crear usuario:", error);
        }
      } finally {
        this.loading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
};
</script>

<style scoped>
.modal-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.modal-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.modal-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  color: #dc3545;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #a71d2a;
  scale: 1.2;
}

.form-group {
  margin-bottom: 15px;
}

.input-container {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 23px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.password-toggle:hover {
  color: #0056b3;
}

.password-group input {
  padding-right: 40px; 
}

.form-group input,
.form-group select {
  width: 92%;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #f8f9fa;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group select {
    width: 99%;
}

.form-group input:hover,
.form-group select:hover {
  border-color: #007bff;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 91, 187, 0.3);
}

.form-group input.error,
.form-group select.error {
  border-color: #dc3545;
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
}

.form-group input::placeholder {
  color: #999;
}

.form-group label {
  font-size: 14px;
  font-weight: 600;
  color: #555;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.select-icon {
  position: absolute;
  left: 10px;
  font-size: 18px;
  color: #007bff;
  pointer-events: none;
}

.error-msg {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-btn {
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 0.3s ease, transform 0.2s;
}

.save-btn:hover {
  background: #0056b3;
  transform: scale(1.05);
}

.success-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #28a745;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 2.7s;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-10px); }
}

</style>
