<template>
  <div class="lines-container">
    <!-- Encabezado -->
    <div class="header">
      <div class="header-left">
        <span class="header-subtitle">CHATBOTS</span> 
        <div class="header-main">
          <ion-icon name="settings-outline" class="header-icon"></ion-icon>
          <h1 class="header-title">Administrar Chatbots</h1> 
        </div>
      </div>
    </div>

    <!-- Lista de Widgets -->
    <div class="widgets-grid">
      <div
        v-for="line in lines"
        :key="line.id"
        class="widget"
        :class="{ 'dimmed': hoveredWidget !== null && hoveredWidget !== line.id }"
        @mouseenter="hoveredWidget = line.id"
        @mouseleave="hoveredWidget = null"
      >
        <ion-icon name="hardware-chip-outline" class="widget-icon"></ion-icon>
        <div class="widget-content">
          <h3 class="widget-title">{{ line.line_name }}</h3>
          <p class="widget-subtitle">{{ line.phone_number }}</p>
        </div>

        <!-- Botón para ir al Builder -->
        <button class="builder-button" @click="goToBuilder(line.id)">
          <ion-icon name="construct-outline"></ion-icon>
          <span>Configurar</span>
        </button>

        <!-- Switch para activar/desactivar chatbot -->
        <div class="switch-container">
          <span>Chatbot</span>
          <label class="switch">
            <input type="checkbox" :checked="line.has_chatbot" @change="toggleChatbot(line)">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChatbotLinesView",
  data() {
    return {
      lines: [],
      hoveredWidget: null,
    };
  },
  methods: {
    async fetchLines() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token no disponible");
          return;
        }

        const response = await axios.get("/lines", {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.lines = response.data; 
      } catch (error) {
        console.error("Error al obtener las líneas:", error);
      }
    },

    async toggleChatbot(line) {
      try {
        const token = localStorage.getItem("token");
        await axios.put(`/lines/${line.id}/toggle-chatbot`, {}, {
          headers: { Authorization: `Bearer ${token}` },
        });

        line.has_chatbot = !line.has_chatbot;
      } catch (error) {
        console.error("Error al cambiar el estado del chatbot:", error);
      }
    },

    goToBuilder(lineId) {
      this.$router.push({ name: "ChatbotBuilder", params: { lineId } });
    }
  },

  mounted() {
    this.fetchLines(); 
  },
};
</script>

<style scoped>
.lines-container {
  padding: 20px;
  background: #f9fafb;
}

.header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 40px;
}

.header-subtitle {
  font-size: 12px;
  color: #999;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
  text-align: start;
}

.header-main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  font-size: 25px;
  color: #1f2937;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
  color: #1f2937;
}

.widgets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.widget {
  background: #ffffff;
  padding: 25px 20px;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: box-shadow 0.3s;
  border: 1px solid #e0e0e0;
}

.widget:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  border-color: #d0d0d0;
}

.widget-icon {
  font-size: 50px;
  color: #4b5563; 
  margin-bottom: 15px;
}

.widget-content {
  margin-bottom: 5px;
}

.widget-title {
  font-size: 18px;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 5px;
}

.widget-subtitle {
  font-size: 14px;
  color: #6b7280;
  font-weight: 500;
}

.builder-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
  width: fit-content;
  margin: 8px 0 17px 0;
}

.builder-button:hover {
  background: #2563eb;
  transform: scale(1.009);
}

.builder-button ion-icon {
  font-size: 18px;
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #1f2937;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1d5db;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4ade80;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.dimmed {
  opacity: 0.6; 
  transition: opacity 0.6s ease-in-out;
}
</style>
