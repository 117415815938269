<template>
  <div class="login-background">
    <div class="login-container">
      <div class="login-logo">
        <img
          src="../assets/logoKiitosSinFondo.png"
          alt="Logo de Empresa"
          class="login-logo__img"
        />
      </div>

      <h2 class="login-title">¡Bienvenido(a) de nuevo!</h2>
      <p class="login-subtitle">Inicie sesión para continuar</p>

      <form @submit.prevent="handleLogin" class="login-form">
        <div class="form-group">
          <label for="username">Usuario</label>
          <div class="input-with-icon">
            <ion-icon name="person-outline"></ion-icon>
            <input
              type="text"
              id="username"
              v-model="username"
              placeholder="Ingresa tu usuario"
              required
            />
          </div>
        </div>

        <div class="form-group">
          <label for="password">Contraseña</label>
          <div class="input-with-icon">
            <ion-icon name="lock-closed-outline"></ion-icon>
            <input
              type="password"
              id="password"
              v-model="password"
              placeholder="Ingresa tu contraseña"
              required
            />
          </div>
        </div>

        <div class="separator"></div>

        <button type="submit" class="login-button" :disabled="isLoading">
          <span v-if="!isLoading">Iniciar Sesión</span>
          <span v-else class="loading-spinner"></span>
        </button>

        <p v-if="cooldownMessage" class="cooldown-message">
          {{ cooldownMessage }}
        </p>
        <p v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      cooldownMessage: "",
      cooldownTime: 0, 
      isLoading: false,
    };
  },
  methods: {
    async handleLogin() {
      this.errorMessage = "";
      this.cooldownMessage = "";
      this.isLoading = true;

      try {
        const response = await this.$axios.post("/auth/login", {
          username: this.username,
          password: this.password,
        });

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user_role", response.data.user.role);

        this.$router.push("/dashboard");
      } catch (error) {
        if (error.response) {
          if (error.response.status === 429) {
            const match = error.response.data.error.match(/\d+/);
            this.cooldownTime = match ? parseInt(match[0]) : 0;

            if (this.cooldownTime > 0) {
              this.startCooldown();
              this.cooldownMessage = `Demasiados intentos. Intenta de nuevo en ${this.cooldownTime} segundos.`;
            }
          } else {
            this.errorMessage = error.response.data.error || "Error al iniciar sesión.";
          }
        } else {
          this.errorMessage = "No se pudo conectar con el servidor.";
        }
      } finally {
        this.isLoading = false;
      }
    },
    startCooldown() {
      const interval = setInterval(() => {
        if (this.cooldownTime > 0) {
          this.cooldownTime -= 1;
          this.cooldownMessage = `Demasiados intentos. Intenta de nuevo en ${this.cooldownTime} segundos.`;
        } else {
          this.cooldownMessage = "";
          clearInterval(interval);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.login-background {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b4bcc5;
  overflow: hidden;
}

.login-container {
  backdrop-filter: blur(12px);
  padding: 2.5rem;
  border-radius: 16px;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.login-logo {
  margin-bottom: 1.5rem;
}
.login-logo__img {
  max-width: 100px;
  height: auto;
}

.login-title {
  font-size: 1.5rem;
  color: #2c3e50;
  font-weight: 700;
  margin-bottom: 0.3rem;
}

.login-subtitle {
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 2rem;
  font-weight: 500;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

label {
  display: block;
  font-size: 0.95rem;
  font-weight: 600;
  color: #34495e;
  margin-bottom: 0.5rem;
}

.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-icon ion-icon {
  position: absolute;
  left: 10px;
  font-size: 1.2rem;
  color: #34495e;
  opacity: 0.7;
}

.input-with-icon input {
  width: 100%;
  padding: 0.8rem 0.8rem 0.8rem 2.5rem; 
  border: 1.5px solid rgba(52, 73, 94, 0.3);
  border-radius: 8px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.6);
  color: #34495e;
  transition: border 0.3s ease-in-out, background 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

.input-with-icon input::placeholder {
  color: rgba(52, 73, 94, 0.6);
}

.input-with-icon input:focus {
  border-color: #5dade2;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 8px rgba(93, 173, 226, 0.5);
  outline: none;
}

.separator {
  width: 100%;
  height: 1px;
  margin: 1.5rem 0; 
  background: rgba(52, 73, 94, 0.2);
}

.login-button {
  width: 70%;
  padding: 1rem;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(135deg, #2d66e2, #0007e0);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.login-button:hover {
  box-shadow: 0 6px 18px rgba(45, 51, 226, 0.6);
}

.error-message {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

.cooldown-message {
  color: #f39c12; 
  font-size: 0.9rem;
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .login-container {
    padding: 2rem;
  }

  .login-title {
    font-size: 1.3rem;
  }

  .login-subtitle {
    font-size: 0.95rem;
  }
}
</style>
