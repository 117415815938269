<template>
  <div class="header">
    <div class="logo">Kiitos</div>

    <div class="header-right">
      <NotificationDropdown />

      <div class="user-menu" ref="userMenu">
        <ion-icon
          name="person-outline"
          class="icon"
          @click="toggleDropdown"
        ></ion-icon>

        <transition name="fade">
          <div v-if="showDropdown" class="dropdown">
            <div class="dropdown-header">
              <ion-icon name="person-circle-outline" class="dropdown-icon"></ion-icon>
              <p class="dropdown-title">
                Hola, {{ user?.username || "Usuario" }}!
              </p>
            </div>
            <div class="dropdown-content">
              <p><strong>Usuario:</strong> {{ user?.username }}</p>
              <p><strong>Rol:</strong> {{ user?.role }}</p>
              <p>
                <strong>Estado:</strong>
                <span :class="user?.status === 'active' ? 'status-active' : 'status-inactive'">
                  {{ user?.status === 'active' ? 'Activo' : 'Inactivo' }}
                </span>
              </p>
            </div>
            <button class="change-password" @click="openChangePassword">
              Cambiar Contraseña
            </button>
          </div>
        </transition>
      </div>

      <button class="logout" @click="logout">
        <ion-icon name="log-out-outline" class="logout-icon"></ion-icon>
      </button>
    </div>

    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-modal">
        <span class="spinner"></span>
        <p>Cerrando sesión...</p>
      </div>
    </div>

    <div v-if="showChangePasswordModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Cambiar Contraseña</h2>
          <ion-icon
            name="close-outline"
            class="close-icon"
            @click="closeChangePasswordModal"
          ></ion-icon>
        </div>

        <form @submit.prevent="handleChangePassword">
          <div class="form-group">
            <label for="current-password">Contraseña Actual</label>
            <div class="input-container">
              <input
                :type="showCurrentPassword ? 'text' : 'password'"
                id="current-password-input" 
                v-model="currentPassword"
                placeholder="Ingresa tu contraseña actual"
                required
              />
              <ion-icon
                :name="showCurrentPassword ? 'eye-off-outline' : 'eye-outline'"
                class="toggle-password"
                @click="toggleCurrentPasswordVisibility"
              ></ion-icon>
            </div>
          </div>

          <!-- Campo de Nueva Contraseña -->
          <div class="form-group">
            <label for="new-password">Nueva Contraseña</label>
            <input
              type="text"
              id="new-password-input" 
              v-model="newPassword"
              placeholder="Ingresa tu nueva contraseña"
              required
            />
          </div>

          <!-- Campo de Confirmar Contraseña -->
          <div class="form-group">
            <label for="confirm-password">Confirmar Contraseña</label>
            <div class="input-container">
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                id="confirm-password-input" 
                v-model="confirmPassword"
                placeholder="Confirma tu nueva contraseña"
                required
              />
              <ion-icon
                :name="showConfirmPassword ? 'eye-off-outline' : 'eye-outline'"
                class="toggle-password"
                @click="toggleConfirmPasswordVisibility"
              ></ion-icon>
            </div>
          </div>
          <div class="modal-actions">
            <button type="submit" class="button-save">Guardar</button>
          </div>
        </form>

        <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>

        <div v-if="successMessage" class="success-message">
          {{ successMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationDropdown from "@/components/notification/NotificationDropdown.vue";

export default {
  name: "HeaderSystem",
  components: {
    NotificationDropdown,
  },
  data() {
    return {
      user: null,
      showDropdown: false,
      showChangePasswordModal: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      showCurrentPassword: false,
      showConfirmPassword: false, 
      successMessage: "",
      errorMessage: "",
      isLoading: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown(event) {
      if (this.showDropdown && !this.$refs.userMenu.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    async fetchUser() {
      try {
        const token = localStorage.getItem("token"); 
        if (!token) {
          throw new Error("No hay token disponible.");
        }

        const response = await this.$axios.get("/me", {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.user = response.data;
      } catch (error) {
        console.error("Error obteniendo usuario:", error);
        this.$router.push("/login");
      }
    },
    async logout() {
      try {
        this.isLoading = true;
        await this.$axios.post("/logout");

        localStorage.removeItem("user_role");
        localStorage.removeItem("token");
        
        this.$root.$emit("stopPollingNotifications");
        this.$router.push("/");
      } catch (error) {
        console.error("Error al cerrar sesión:", error);
      } finally {
        this.isLoading = false;
      }
    },
    openChangePassword() {
      this.showDropdown = false;
      this.showChangePasswordModal = true;
    },
    toggleCurrentPasswordVisibility() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    closeChangePasswordModal() {
      this.successMessage = "";
      this.errorMessage = "";
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.showChangePasswordModal = false;
    },
    async handleChangePassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = "❌ Las contraseñas no coinciden. Por favor, inténtalo de nuevo.";
        setTimeout(() => { this.errorMessage = ""; }, 3000);
        return;
      }

      try {
        const response = await this.$axios.post("/users/change-password", {
          current_password: this.currentPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.confirmPassword,
        });

        console.log("Contraseña cambiada con éxito:", response.data.message);

        localStorage.removeItem("user_role");
        localStorage.removeItem("token");

        window.location.href = "https://metricwsfront.cwp.mx/";
      } catch (error) {
        this.errorMessage = error.response?.data?.message || "Error al cambiar la contraseña";
        console.error("Error en cambio de contraseña:", error);
      }
    },
  },
  mounted() {
    this.fetchUser();
    document.addEventListener("click", this.closeDropdown);
    this.$root.$on("stopPollingNotifications", () => {
      this.$refs.notificationDropdown?.stopPolling();
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  },
};
</script>

<style scoped>
.header {
  background: #2c3e50;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
}

.logo {
  font-size: 25px;
  font-weight: 700;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  transition: color 0.2s ease;
}
.icon:hover {
  color: #e0e0e0;
}

.logout {
  display: flex;
  align-items: center;
  background: #e74c3c;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.2s ease;
}
.logout:hover {
  background: #c0392b;
}

.loading-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loading-modal {
  background: white;
  padding: 20px 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.spinner {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto 10px;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-modal p {
  font-size: 16px;
  color: #2c3e50;
  margin: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logout-icon {
  font-size: 22px;
}

.user-menu {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 50px;
  right: -80px;
  background: #ffffff;
  color: #2c3e50;
  width: 220px;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Arial', sans-serif;
  transform-origin: top right;
}

.dropdown-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.dropdown-icon {
  font-size: 30px;
  color: #3498db;
}

.dropdown-title {
  font-weight: bold;
  font-size: 16px;
  color: #34495e;
}

.dropdown-content {
  margin-bottom: 15px;
}

.dropdown-content p {
  margin: 5px 0;
  font-size: 14px;
}

.status-active {
  color: #27ae60;
  font-weight: bold;
}

.status-inactive {
  color: #e74c3c;
  font-weight: bold;
}

.change-password {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: white;
  background: #bdc3c7;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.change-password:hover {
  background: #95a5a6;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: #ffffff;
  padding: 20px 30px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  text-align: left;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 0;
}

.modal-header .close-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #34495e;
  transition: color 0.3s ease;
}

.modal-header .close-icon:hover {
  color: #e74c3c;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-size: 0.95rem;
  color: #34495e;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.input-container {
  position: relative;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 1rem;
}

input[type="password"],
input[type="text"] {
  color: black !important;
  background: white !important;
  opacity: 1 !important;
  visibility: visible !important;
  font-size: 16px !important;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 1.2rem;
  color: #34495e;
  cursor: pointer;
}

.toggle-password:hover {
  color: #3498db;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.button-save {
  background: #bdc3c7;
  border: none;
  color: #2c3e50;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.button-save:hover {
  background: #95a5a6;
}

.success-message {
  margin-top: 15px;
  padding: 10px;
  background: #dff0d8;
  color: #3c763d;
  border-radius: 8px;
  text-align: center;
}

.error-message {
  margin-top: 15px;
  padding: 10px;
  background: #f5f5f5;
  color: #be1818;
  border-radius: 8px;
  text-align: center;
}

</style>
