<template>
  <div class="filters-container">

    <div class="filter-group">
      <ion-icon name="search-outline"></ion-icon>
      <input
        v-model="filters.search"
        type="text"
        placeholder="Buscar por nombre o número"
        class="filter-input"
        @input="applyFilters"
      />
    </div>

    <div class="filter-group">
      <ion-icon name="call-outline"></ion-icon>
      <select v-model="filters.line" class="filter-dropdown" @change="applyFilters">
        <option v-for="line in lines" :key="line.id" :value="line.id">
          {{ line.name }}
        </option>
      </select>
    </div>

    <div class="filter-group">
      <ion-icon name="pricetag-outline"></ion-icon>
      <select v-model="filters.tags" class="filter-dropdown" @change="applyFilters">
        <option value="">Todos los tags</option>
        <option v-for="tag in tags" :key="tag.id" :value="tag.name">
          {{ tag.name }}
        </option>
      </select>
    </div>

    <hr class="divider" />
  </div>
</template>

<script>
export default {
  props: {
    lines: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    selectedLine: { 
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      filters: {
        search: '',
        line: this.lines.length > 0 ? this.lines[0].id : '',
        tags: ''
      }
    };
  },
  watch: {
    lines: {
      immediate: true,
      handler(newLines) {
        if (newLines.length > 0 && !this.filters.line) {
          this.filters.line = newLines[0].id; 
        }
      }
    }
  },
  methods: {
    applyFilters() {
      this.$emit('filter-changed', this.filters);
    }
  }
};
</script>

<style scoped>
.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 10px;
  gap: 50px;
}

.filter-group {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  border-radius: 8px;
  padding: 8px 12px;
  transition: all 0.3s ease;
  width: 100%;
}

.filter-group:hover {
  background: #eef2f7;
}

.filter-group ion-icon {
  font-size: 20px;
  color: #007bff;
  margin-right: 8px;
}

.filter-input, .filter-dropdown {
  width: 100%;
  padding: 8px 10px;
  border: none;
  background: transparent;
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease;
}

.divider {
  margin: 15px 0;
  border: 0;
  border-top: 1px solid #e9ecef;
}

@media (max-width: 768px) {
  .filters-container {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
