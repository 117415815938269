<template>
  <div class="modal-overlay">
    <transition name="modal">
      <div class="modal-container">
        <div class="modal-header">
          <h2><ion-icon name="alert-circle-outline" class="warning-icon"></ion-icon> Confirmar Eliminación</h2>
        </div>

        <div class="modal-body">
          <p v-if="user">
            ¿Estás seguro de que deseas eliminar al usuario <strong>{{ user.username }}</strong>?
          </p>
          <p class="warning-msg">
            Esta acción no se puede deshacer y los datos del usuario serán eliminados permanentemente.
          </p>
        </div>

        <div class="modal-footer">
          <button class="cancel-btn" @click="closeModal">Cancelar</button>
          <button class="delete-btn" @click="$emit('confirm-delete')">Eliminar</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}
.modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  border-radius: 12px;
  padding: 25px;
  width: 420px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.modal-header h2 {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.warning-icon {
  font-size: 32px;
  color: #dc3545;
}

.modal-body {
  padding: 15px 0;
  text-align: center;
}

.modal-body p {
  font-size: 15px;
  color: #333;
}

.warning-msg {
  color: #dc3545;
  font-weight: bold;
  margin-top: 5px;
}

.modal-footer {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  margin-top: 15px;
}

.cancel-btn {
  background: #6c757d;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s;
  width: 100px;
}

.cancel-btn:hover {
  background: #5a6268;
  transform: scale(1.04);
}

.delete-btn {
  background: #dc3545;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s;
  width: 100px;
}

.delete-btn:hover {
  background: #c82333;
  transform: scale(1.04);
}
</style>
