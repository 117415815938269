import Vue from "vue";
import Router from "vue-router";
import LoginKiitos from "@/views/LoginKiitos.vue";
import MainLayout from "@/Layouts/MainLayout.vue";
import MessagesMetrics from "@/views/Metrics/MessagesMetrics.vue";
import ContactsMetrics from "@/views/Metrics/ContactsMetrics.vue";
import ChatView from "@/views/ChatView.vue";
import ChatbotDashboard from "@/views/Chatbot/ChatbotDashboard.vue";
import ContactsView from "@/views/ContactsView.vue";
import UsersView from "@/views/UsersView.vue";
import LinesView from "@/views/LinesView.vue";
import PrivacyPolicy from "@/static/PrivacyPolicy.vue";
import TermsOfService from "@/static/TermsOfService.vue"

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Login",
      component: LoginKiitos,
      meta: { guest: true },
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy, 
    },
    {
      path: "/terms-of-service",
      name: "TermsOfService",
      component: TermsOfService,
    },
    {
      path: "/dashboard",
      component: MainLayout,
      meta: { requiresAuth: true },
      children: [
        { path: "/dashboard/metrics/messages", name: "MessagesMetrics", component: MessagesMetrics },
        { path: "/dashboard/metrics/contacts", name: "ContactsMetrics", component: ContactsMetrics },
        { path: "/dashboard/chat", name: "Chat", component: ChatView },
        { path: "/dashboard/chat/:lineId", name: "ChatPage", component: () => import("@/views/ChatPage.vue"), props: true },
        { path: "/dashboard/chatbot", name: "ChatbotDashboard", component: ChatbotDashboard, },
        { path: "/dashboard/chatbot/:lineId", name: "ChatbotBuilder", component: () => import("@/views/Chatbot/ChatbotBuilder.vue"), props: true },
        { path: "/dashboard/contacts", name: "Contacts", component: ContactsView },
        { path: "/dashboard/users", name: "Users", component: UsersView },
        { path: "/dashboard/lines", name: "Lines", component: LinesView },
      ],
    },    
  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next("/");
  } else if (to.matched.some((record) => record.meta.guest) && token) {
    next("/dashboard/metrics/messages");
  } else {
    next();
  }
});

export default router;
