<template>
  <div class="contacts-view">
    <!-- Botón para agregar contacto -->
    <button v-if="userRole !== 'agent'" class="add-contact-btn" @click="openAddForm">
      <ion-icon name="add-circle-outline" class="icon-add"></ion-icon> 
    </button>

    <!-- Filtros -->
    <ContactFilters 
      :lines="lines" 
      :tags="tags" 
      @filter-changed="applyFilters" 
    />

    
    <!-- Tabla de Contactos -->
    <ContactTable 
      :contacts="paginatedContacts" 
      :userRole="userRole" 
      @edit-contact="openEditForm" 
      @delete-contact="openDeleteModal" 
    />

    <div class="pagination">
      <button 
        @click="changePage(currentPage - 1)" 
        :disabled="currentPage === 1"
        class="pagination-button"
      >
        <ion-icon name="chevron-back-outline"></ion-icon>
      </button>

      <button 
        @click="changePage(currentPage + 1)" 
        :disabled="currentPage === totalPages"
        class="pagination-button"
      >
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </button>
    </div>
    
    <!-- Modal de Confirmación -->
    <ConfirmDeleteModal v-if="deleteContactId" 
      @confirm-delete="deleteContact" 
      @cancel="deleteContactId = null" 
    />
    
    <!-- Crear Contacto -->
    <div v-if="showContactForm">
      <div class="modal-overlay" @click="closeModal"></div>
      <ContactForm 
        :contact="editingContact" 
        :lines="lines" 
        :tags="tags" 
        :countryCodes="countryCodes" 
        @close="closeModal" 
        @save="saveContact" 
      />
    </div>

    <!-- Editar Contacto -->
    <EditContactModal 
      v-if="showEditModal" 
      :contact="editingContact" 
      :lines="lines"
      @close="showEditModal = false" 
      @updated="fetchContacts"
    />
  </div>
</template>

<script>
import ContactFilters from '@/components/contacts/ContactFilters.vue';
import ContactTable from '@/components/contacts/ContactTable.vue';
import ContactForm from '@/components/contacts/ContactForm.vue';
import EditContactModal from '@/components/contacts/EditContactModal.vue';
import ConfirmDeleteModal from '@/components/contacts/ConfirmDeleteModal.vue';

export default {
  components: { ContactFilters, ContactTable, ContactForm, EditContactModal, ConfirmDeleteModal },
  data() {
    return {
      contacts: [],
      lines: [],
      tags: [],
      selectedContact: null,
      deleteContactId: null,
      showContactForm: false,
      showEditModal: false,
      filteredContacts: [],
      filters: { 
        search: '',
        line: '', 
        tags: '',
      },
      currentPage: 1, 
      itemsPerPage: 100,
      prefix: '+52',
      countryCodes: [
        { id: 'mx', emoji: '🇲🇽', value: '521'},
        { id: 'us', emoji: '🇺🇸', value: '1'},
        { id: 'ca', emoji: '🇨🇦', value: '1'},
        { id: 'co', emoji: '🇨🇴', value: '57'},
        { id: 've', emoji: '🇻🇪', value: '58'},
      ],
      userRole: null,
    };
  },
  computed: {
    paginatedContacts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredContacts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredContacts.length / this.itemsPerPage);
    },
  },
  methods: {
    changePage(newPage) {
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.currentPage = newPage;
      }
    },
    async fetchContacts() {
      try {
        const params = {
          search: this.filters.search || undefined,
          line_id: this.filters.line || undefined,
          tags: this.filters.tags || undefined,
        };

        const response = await this.$axios.get('/contacts/table', { params });
        this.contacts = response.data;
        this.filteredContacts = this.contacts; 
      } catch (error) {
        console.error("Error al obtener contactos:", error);
      }
    },
    async fetchLines() {
      try {
        const response = await this.$axios.get('/lines');

        this.lines = response.data.map(line => ({
          id: line.id,
          name: line.line_name || 'Desconocido',
        }));


        if (this.lines.length > 0) {
          this.filters.line = this.lines[0].id;
          this.applyFilters(this.filters); 
        }
      } catch (error) {
        console.error("Error al obtener líneas:", error);
      }
    },
    async fetchTags() {
      try {
        const response = await this.$axios.get('/tags');
        this.tags = response.data.map(tag => ({
          id: tag.id,
          name: tag.name,
          color: tag.color, 
        }));
      } catch (error) {
        console.error("Error al obtener etiquetas:", error);
      }
    },
    async saveContact(contactData) {
      try {
        const payload = {
          phone_number: contactData.phone, 
          name: contactData.name,
          line_id: contactData.line,
          status: "active", 
        };

        await this.$axios.post('/contacts', payload);
        this.fetchContacts();
        this.showContactForm = false;
      } catch (error) {
        console.error("Error al guardar contacto:", error.response?.data || error.message);
      }
    },
    async deleteContact() {
      try {
        await this.$axios.delete(`/contacts/${this.deleteContactId}`);
        this.fetchContacts();
        this.deleteContactId = null;
      } catch (error) {
        console.error("Error al eliminar contacto:", error);
      }
    },
    async fetchUserRole() {
      try {
        const response = await this.$axios.get('/me'); 
        this.userRole = response.data.role;
      } catch (error) {
        console.error('Error al obtener el rol del usuario:', error);
        this.userRole = 'agent'; 
      }
    },
    async applyFilters(filters) {
      this.filters = filters;
      this.currentPage = 1;
      await this.fetchContacts();
    },
    openEditForm(contact) {
      this.editingContact = contact;
      this.showEditModal = true;
    },
    openDeleteModal(contactId) {
      this.deleteContactId = contactId;
    },
    openAddForm() {
      this.editingContact = null;
      this.showContactForm = true;
    },
    closeModal() {
      this.showContactForm = false;
    },
  },
  mounted() {
    this.fetchContacts();
    this.fetchLines();
    this.fetchTags();
    this.fetchUserRole();
  }
};
</script>

<style scoped>
html, body {
  overflow: auto; 
}

.contacts-view {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto; 
}

.add-contact-btn {
  background-color: #007bff;
  color: white;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  width: 50px;
}

.add-contact-btn:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button ion-icon {
  font-size: 18px;
}

.pagination-button:hover {
  background-color: #0056b3;
}

.pagination-button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.icon-add {
  font-size: 24px;
}

.form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 400px;
  max-width: 90%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

</style>
