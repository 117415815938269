<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-title">
          <ion-icon name="warning-outline" class="warning-icon"></ion-icon>
          <h3>¡Advertencia!</h3>
        </div>
      </div>

      <div class="modal-body">
        <p>
          ¿Estás seguro de que deseas eliminar este contacto?
        </p>
        <p class="alert-message">
          Todos los mensajes asociados a este contacto también serán eliminados de forma permanente. <strong>Esta acción no se puede deshacer.</strong>
        </p>
      </div>

      <div class="modal-footer">
        <button class="btn btn-cancel" @click="$emit('cancel')">
          <ion-icon name="close-circle-outline"></ion-icon> Cancelar
        </button>
        <button class="btn btn-confirm" @click="$emit('confirm-delete')">
          <ion-icon name="trash-outline"></ion-icon> Eliminar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDeleteModal",
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal {
  background: white;
  border-radius: 12px;
  width: 420px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-out;
}

.modal-header {
  display: flex;
  align-items: center;
  background: #dc3545;
  color: white;
  padding: 16px 20px;
}

.modal-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: bold;
}

.warning-icon {
  font-size: 32px;
}

.modal-body {
  padding: 20px;
  font-size: 16px;
  color: #495057;
  text-align: center;
  line-height: 1.5;
}

.alert-message {
  font-size: 14px;
  color: #6c757d;
  margin-top: 12px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 20px;
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 18px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.btn-cancel {
  background: #6c757d;
  color: white;
}

.btn-cancel:hover {
  background: #5a6268;
  transform: scale(1.05);
}

.btn-confirm {
  background: #dc3545;
  color: white;
}

.btn-confirm:hover {
  background: #c82333;
  transform: scale(1.05);
}
</style>
