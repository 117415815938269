<template>
  <div class="lines-container">
    <!-- Encabezado -->
    <div class="header">
      <div class="header-left">
        <span class="header-subtitle">LINEAS</span> 
        <div class="header-main">
          <ion-icon name="logo-whatsapp" class="header-icon"></ion-icon>
          <h1 class="header-title">Selecciona una Línea</h1> 
        </div>
      </div>
    </div>

    <!-- Lista de Widgets -->
    <div class="widgets-grid">
      <div
        v-for="line in lines"
        :key="line.id"
        class="widget"
        :class="{ 'dimmed': hoveredWidget !== null && hoveredWidget !== line.id }"
        @mouseenter="hoveredWidget = line.id"
        @mouseleave="hoveredWidget = null"
      >
        <ion-icon name="logo-whatsapp" class="widget-icon"></ion-icon>
        <div class="widget-content">
          <h3 class="widget-title">{{ line.line_name }}</h3>
          <p class="widget-subtitle">{{ line.phone_number }}</p>
        </div>
        <button class="widget-button" @click="goToChat(line.id)">
          <ion-icon name="chatbubble-ellipses-outline"></ion-icon> Ir al chat
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LinesSelector",
  data() {
    return {
      lines: [],
      hoveredWidget: null,
    };
  },
  methods: {
    async fetchLines() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token no disponible");
          return;
        }

        const response = await axios.get("/lines", {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.lines = response.data; 
      } catch (error) {
        console.error("Error al obtener las líneas:", error);
      }
    },
    goToChat(lineId) {
      this.$router.push({ name: "ChatPage", params: { lineId } });
    },
  },
  mounted() {
    this.fetchLines(); 
  },
};
</script>

<style scoped>
.lines-container {
  padding: 20px;
  background: #f9fafb;
}

.header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 40px;
}

.header-subtitle {
  font-size: 12px;
  color: #999;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
  text-align: start;
}

.header-main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  font-size: 25px;
  color: #25d366;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.widgets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.widget {
  background: linear-gradient(135deg, #e9f5e6, #ffffff);
  padding: 25px 20px;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #e0e0e0;
}

.widget:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  border-color: #d0d0d0;
}

.widget-icon {
  font-size: 60px;
  color: #25d366; 
  margin-bottom: 20px;
  background: rgba(37, 211, 102, 0.1);
  padding: 15px;
  border-radius: 50%;
}

.widget-content {
  margin-bottom: 25px;
}

.widget-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.widget-subtitle {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.widget-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: linear-gradient(135deg, #25d366, #128c7e); 
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 12px 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
}

.widget-button:hover {
  background: linear-gradient(135deg, #128c7e, #0b6f5a); 
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.widget-button:active {
  background: linear-gradient(135deg, #0b6f5a, #075e48); 
  transform: translateY(1px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.widget-button ion-icon {
  font-size: 18px;
}

.dimmed {
  opacity: 0.6; 
  transform: scale(0.98);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

@media (max-width: 768px) {
  .widgets-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .widget {
    padding: 20px 15px;
  }

  .widget-icon {
    font-size: 50px;
    padding: 12px;
  }

  .widget-title {
    font-size: 18px;
  }

  .widget-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
</style>
