<template>
  <div class="metrics-container">
    <!-- Encabezado -->
    <div class="header">
      <div class="header-left">
        <span class="header-subtitle">MÉTRICAS</span>
        <div class="header-main">
          <ion-icon name="people-outline" class="header-icon"></ion-icon>
          <h1 class="header-title">Contactos</h1>
        </div>
      </div>

      <div class="header-right">
        <button class="filter-button" @click="openModal('date')">
          <ion-icon name="calendar-outline"></ion-icon>
        </button>

        <div class="dropdown-container">
          <button class="filter-button" @click="toggleLineDropdown">
            <ion-icon name="options-outline"></ion-icon>
            {{ selectedLine ? getLineName(selectedLine) : 'General' }}
          </button>
          <ul v-if="showLineDropdown" class="line-dropdown">
            <li v-for="line in lines" :key="line.id" @click="applyLineFilter(line.id)">
              {{ getLineName(line.id) }}
            </li>
            <li @click="applyLineFilter(null)">General</li>
          </ul>
        </div>
      </div>
    </div>

    <div v-show="showModal" :class="{ show: showModal }" class="modal-overlay">
      <div class="modal-content">
        <h2>Seleccionar rango de fechas</h2>

        <div class="modal-body">
          <div class="modal-inputs">
            <div>
              <label for="start-date">Fecha de inicio:</label>
              <input type="date" id="start-date" v-model="dateFilters.startDate" />
            </div>
            <div>
              <label for="end-date">Fecha de fin:</label>
              <input type="date" id="end-date" v-model="dateFilters.endDate" />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn-confirm" @click="applyFilters" :disabled="!isDateValid">
            Confirmar
          </button>
          <button class="btn-cancel" @click="closeModal">Cerrar</button>
        </div>
        
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>

    <!-- Botón para abrir el modal de filtros aplicados -->
    <div v-if="hasActiveFilters" class="filters-dropdown-container">
      <button class="filters-toggle" @click="toggleFiltersDropdown">
        <ion-icon name="filter-outline"></ion-icon> Filtros Aplicados
      </button>

      <!-- Dropdown de Filtros Aplicados -->
      <div class="filters-dropdown" :class="{ show: showFiltersDropdown }">
        <p v-if="filters.startDate"><strong>Fecha Inicio:</strong> {{ filters.startDate }}</p>
        <p v-if="filters.endDate"><strong>Fecha Fin:</strong> {{ filters.endDate }}</p>
        <p v-if="filters.line"><strong>Línea:</strong> {{ getLineName(filters.line) }}</p>
        <button class="btn-clear" @click="clearFilters">Limpiar Filtros</button>
      </div>

    </div>

    <!-- Métricas Widgets -->
    <div class="metrics-grid">
      <!-- Total de Contactos -->
      <div class="widget widget-small">
        <span class="widget-title">Total de Contactos</span>
        <ion-icon name="people-circle-outline" class="widget-icon"></ion-icon>
        <p class="widget-value">{{ metrics.total_contacts }}</p>
      </div>

      <!-- Usuarios por Línea -->
      <div class="widget widget-large">
        <span class="widget-title">Usuarios por Línea</span>
        <ion-icon name="business-outline" class="widget-icon"></ion-icon>
        <ul class="widget-list">
          <li v-for="line in metrics.contacts_by_line" :key="line.line_name">
            {{ line.line_name }}: {{ line.total }}
          </li>
        </ul>
      </div>

      <!-- Contactos por Etiqueta -->
      <div class="widget widget-large">
        <span class="widget-title">Contactos por Etiqueta</span>
        <ion-icon name="pricetag-outline" class="widget-icon"></ion-icon>
        <ul class="widget-list">
          <li v-for="tag in metrics.contacts_by_tag" :key="tag.name">
            <span :style="{ color: tag.color }">{{ tag.name }}</span>: {{ tag.total }}
          </li>
        </ul>
      </div>

      <!-- Contactos por Estado -->
      <div class="widget widget-large">
        <span class="widget-title">Contactos por Estado</span>
        <ion-icon name="pulse-outline" class="widget-icon"></ion-icon>
        <ul class="widget-list">
          <li v-for="status in metrics.contacts_by_status" :key="status.status">
            {{ status.status }}: {{ status.total }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactsMetrics",
  data() {
    return {
      // Filtros Fecha | Linea
      showModal: false,
      modalType: "", 
      errorMessage: "",
      dateFilters: {
        startDate: "",
        endDate: "",
      },
      filters: {
        startDate: "",
        endDate: "",
        line: null,
      },
      lines: [],
      selectedLine: null,
      showLineDropdown: false,
      showFiltersDropdown: false,
      // Métricas
      metrics: {
        total_contacts: 0,
        contacts_by_status: [],
        contacts_by_line: [],
        contacts_by_tag: [],
      },
    };
  },
  computed: {
    isDateValid() {
      return this.dateFilters.startDate && this.dateFilters.endDate;
    },
    hasActiveFilters() {
      return this.filters.startDate || this.filters.endDate || this.filters.line;
    },
  },
  methods: {
    async fetchMetrics() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token no disponible");
          return;
        }

        const { startDate, endDate, line } = this.filters;
        const params = {};
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;
        if (line) params.line = line;

        const response = await axios.get("/metrics/contacts", {
          headers: { Authorization: `Bearer ${token}` },
          params: params,
        });

        this.metrics = response.data;
      } catch (error) {
        console.error("❌ Error fetching metrics:", error.response || error);
      }
    },
    async fetchLines() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token no disponible");
          return;
        }

        const response = await axios.get("/lines", {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.lines = response.data;
      } catch (error) {
        console.error("❌ Error fetching lines:", error.response || error);
      }
    },
    applyFilters() {
      if (!this.isDateValid) {
        this.showError("Debe seleccionar ambas fechas.");
        return;
      }

      this.filters.startDate = this.dateFilters.startDate;
      this.filters.endDate = this.dateFilters.endDate;
      this.closeModal();
      this.fetchMetrics();
    },

    applyLineFilter(lineId) {
      this.selectedLine = lineId;
      this.filters.line = lineId;
      this.showLineDropdown = false;
      this.fetchMetrics();
    },
    openModal(type) {
      this.modalType = type;
      this.showModal = true;
      this.errorMessage = "";
    },
    closeModal() {
      this.showModal = false;
    },
    closeDropdowns(event) {
      if (!this.$el.querySelector(".dropdown-container")?.contains(event.target)) {
        this.showLineDropdown = false;
      }
    },
    toggleLineDropdown() {
      this.showFiltersDropdown = false; 
      this.showLineDropdown = !this.showLineDropdown;
    },
    toggleFiltersDropdown() {
      this.showFiltersDropdown = !this.showFiltersDropdown;
    },
    clearFilters() {
      this.filters = { startDate: "", endDate: "", line: null };
      this.dateFilters.startDate = "";
      this.dateFilters.endDate = "";
      this.selectedLine = null;
      this.fetchMetrics();
    },
    getLineName(lineId) {
      const line = this.lines.find(line => line.id === lineId);
      return line ? line.line_name : "General";
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdowns);
  },
  mounted() {
    this.fetchMetrics();
    this.fetchLines();

    document.addEventListener("click", this.closeDropdowns);
  },
};
</script>

<style scoped>
html, body {
  overflow: auto; 
}

.metrics-container {
  height: calc(100vh - 100px);
  padding: 20px;
  background: #f9fafb;
  max-height: 100vh;
  overflow-y: auto; 
}

.header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 40px;
}

.header-subtitle {
  font-size: 12px;
  color: #999;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}

.header-main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  font-size: 25px;
  color: #007bff;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); 
  gap: 15px;
  margin-bottom: 20px;
}

.widget {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.widget-title {
  font-size: 12px;
  color: #6c757d;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.widget-icon {
  font-size: 24px;
  color: #007bff;
}

.widget-value {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.widget-list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: #555;
}

.widget-list li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
}

.widget-list .list-label {
  font-weight: bold;
}

.widget-list .list-value {
  color: #333;
}

.compact-left {
  align-self: flex-start;
}


.no-data {
  text-align: center;
  font-style: italic;
  color: #999;
  padding: 10px;
}

@media (max-width: 768px) {
  .compact-table-container {
    max-width: 100%; 
    padding: 8px;
  }
}

/* Modal Fecha */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; 
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  width: 480px;
  max-width: 90%;
  animation: scaleIn 0.4s ease;
  border: 1px solid #e3e3e3;
  text-align: center;
}

.modal-content h2 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-inputs {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.modal-inputs div {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.modal-inputs label {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 8px;
}

.modal-inputs input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  transition: border-color 0.3s ease;
}

.modal-inputs input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  outline: none;
}

.error-message {
  margin-top: 15px;
  padding: 10px;
  background: #f5f5f5;
  color: #be1818; 
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.5s ease-in-out 2.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
}

.btn-confirm {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-confirm:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-cancel:hover {
  background-color: #495057;
  transform: scale(1.05);
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Filtro Boton - Dropdawn */
.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}


.filter-button {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.filter-button:hover {
  background-color: #0056b3;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.line-dropdown {
  position: absolute;
  top: 100%; 
  left: 0;
  z-index: 1000;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  width: 150px;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

.line-dropdown li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  width: 100%;
}

.line-dropdown li:hover {
  background-color: #f1f1f1;
}

.filters-dropdown-container {
  position: relative;
  margin-top: -20px;
  margin-bottom: 25px;
  display: flex;
  justify-content: left;
}

.filters-toggle {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background 0.3s ease-in-out;
}

.filters-toggle:hover {
  background: #0056b3;
}

.filters-dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  background: white;
  width: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: left;
  z-index: 1000;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}

.filters-dropdown.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.filters-dropdown p {
  font-size: 14px;
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  background: #f5f5f5;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn-clear {
  background: #dc3545;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.btn-clear:hover {
  background: #bd2130;
  transform: scale(1.05);
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.line-dropdown {
  position: absolute;
  top: 100%; 
  left: 0;
  top: 20px;
  z-index: 1000;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  width: 100%;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

.line-dropdown li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  width: 100%; 
}

.line-dropdown li:hover {
  background-color: #f1f1f1;
}

.filter-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease-in-out;
}

.filter-button:hover {
  background-color: #0056b3;
}
</style>
