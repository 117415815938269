<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-container">
      <h2 class="modal-title">Editar Contacto</h2>
      
      <div v-if="editedContact" class="modal-content">
        <div class="input-row">
          <div class="input-group">
            <label for="name">Nombre</label>
            <input type="text" v-model="editedContact.name" class="input-field" />
          </div>
          <div class="input-group">
            <label for="last_name">Apellido</label>
            <input type="text" v-model="editedContact.last_name" class="input-field" />
          </div>
        </div>
        
        <div class="input-group">
          <label for="office">Oficina</label>
          <input type="text" v-model="editedContact.office" class="input-field" />
        </div>
        
        <div class="input-group">
          <label for="line">Línea</label>
          <select v-model="editedContact.line_id" class="input-field">
            <option v-for="line in lines" :key="line.id" :value="line.id">
              {{ line.name }}
            </option>
          </select>
        </div>
        
        <div class="modal-actions">
          <button @click="$emit('close')" class="btn cancel-btn">Cancelar</button>
          <button @click="updateContact" class="btn save-btn">Guardar</button>
        </div>
      </div>
      <div v-else class="loading-text">
        Cargando datos del contacto...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: Object,
    lines: Array,
  },
  data() {
    return {
      editedContact: null,
    };
  },
  watch: {
    contact: {
      immediate: true,
      handler(newContact) {
        if (newContact && typeof newContact === 'object') {
          this.editedContact = { ...newContact };
        }
      },
    },
  },
  methods: {
    async updateContact() {
      try {
        const token = localStorage.getItem("token");

        const response = await this.$axios.post(
          `/contacts/update/${this.editedContact.id}`,
          {
            name: this.editedContact.name,
            last_name: this.editedContact.last_name,
            office: this.editedContact.office,
            line_id: this.editedContact.line_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Respuesta del servidor:", response.data);
        this.$emit("updated");
        this.$emit("close");
      } catch (error) {
        console.error("Error al actualizar el contacto:", error.response?.data || error.message);
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.modal-container {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  width: 500px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #222;
  text-transform: uppercase;
}

.input-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.input-group {
  flex: 1;
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #444;
  font-size: 14px;
  text-transform: uppercase;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  transition: all 0.3s ease;
  background: #f9f9f9;
}

.input-field:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.4);
  background: white;
  outline: none;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 6px;
  transition: 0.3s;
  text-transform: uppercase;
  font-weight: bold;
}

.cancel-btn {
  background: #e74c3c;
  color: white;
}

.cancel-btn:hover {
  background: #c0392b;
}

.save-btn {
  background: #3498db;
  color: white;
}

.save-btn:hover {
  background: #2980b9;
}

.loading-text {
  font-size: 18px;
  font-weight: bold;
  color: #666;
}
</style>
