<template>
  <div class="layout" :class="{ 'full-chat': isChatPage }">
    <HeaderSystem v-if="!isChatPage" />

    <div class="main-content">
      <SidebarSystem v-if="!isChatPage" @section-selected="hideLogo" />

      <div class="page-content">
        <transition name="slide-fade" mode="out-in">
          <div v-if="showLogo && !isChatPage" class="decorative-logo">
            <img
              src="../assets/logoKiitosSinFondo.png"
              alt="Decorative Logo"
              class="logo-gradient"
            />
          </div>
        </transition>

        <transition name="slide-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSystem from "@/components/HeaderSystem.vue";
import SidebarSystem from "@/components/SidebarSystem.vue";

export default {
  name: "MainLayout",
  components: {
    HeaderSystem,
    SidebarSystem,
  },
  computed: {
    isChatPage() {
      return this.$route.name === "ChatPage" || this.$route.name === "ChatbotBuilder";
    }
  },
  data() {
    return {
      showLogo: true, 
    };
  },
  watch: {
    $route(to) {
      this.showLogo = to.path === "/dashboard";
    },
  },
  methods: {
    hideLogo() {
      this.showLogo = false;
    },
  },
  mounted() {
    this.showLogo = this.$route.path === "/dashboard";
  },
};
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #f0f4f8;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex: 1;
}

.page-content {
  flex: 1;
  overflow-y: auto;
  background: #ffffff;
  border-left: 1px solid #e0e0e0;
  position: relative;
}

.decorative-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.08;
}

.logo-gradient {
  width: 350px;
  height: auto;
  border-radius: 50%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.full-chat {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.full-chat .main-content {
  flex: 1;
  display: flex;
}

.full-chat .page-content {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-left: none;
  background: #f9fafb;
}

.slide-fade-enter-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-fade-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateX(30px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

</style>
