<template>
  <div class="user-table-container">

    <div class="top-bar">
      <button class="add-contact-btn" @click="showCreateUserModal = true">
        <ion-icon name="add-circle-outline" class="icon-add"></ion-icon> 
      </button>
      <div class="filters-container">
        <div class="filter-group">
          <ion-icon name="people-outline" class="select-icon"></ion-icon>
          <select id="role-filter" v-model="selectedRole" @change="applyFilters">
            <option value="">Todos los roles</option>
            <option v-for="role in roles" :key="role" :value="role">
              {{ role }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <table class="user-table">
      <thead>
        <tr>
          <th>Nombre de Usuario</th>
          <th>Rol</th>
          <th>Estado</th>
          <th>Acciones</th>
          <th>Notificaciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="paginatedUsers.length === 0">
          <td colspan="5" class="no-data">No hay usuarios disponibles</td>
        </tr>

        <tr v-for="user in paginatedUsers" :key="user.id">
          <td>
            <div class="username-container">
              <span v-if="user.id === currentUserId" class="current-user-tag">[TU]</span>
              {{ user.username }}
            </div>
          </td>

          <td>{{ user.role }}</td>

          <td>
            <span :class="['connection-status', user.is_online ? 'online' : 'offline']">
              {{ user.is_online ? 'Conectado' : 'Desconectado' }}
            </span>
          </td>

          <td class="action-buttons">
            <button 
              :disabled="user.id === currentUserId || user.is_online" 
              class="icon-button edit" 
              @click="editUser(user)"
            >
              <ion-icon name="create-outline"></ion-icon>
            </button>

            <button 
              :disabled="user.id === currentUserId || user.is_online" 
              class="icon-button delete" 
              @click="openDeleteModal(user)"
            >
              <ion-icon name="trash-outline"></ion-icon>
            </button>
          </td>

          <td>
            <button class="icon-button lines" @click="openManageLinesModal(user)">
                <ion-icon name="link-outline"></ion-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <ManageLinesModal 
      v-if="showManageLinesModal" 
      :user="selectedUserForLines" 
      @close="showManageLinesModal = false"
      @lines-updated="fetchUsers"
    />

    <CreateUserModal v-if="showCreateUserModal" @close="showCreateUserModal = false" @user-created="fetchUsers" />

    <div class="pagination">
      <button
        @click="changePage(currentPage - 1)"
        :disabled="currentPage === 1"
        class="pagination-button"
      >
        <ion-icon name="chevron-back-outline"></ion-icon>
      </button>
      <button
        @click="changePage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        class="pagination-button"
      >
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </button>
    </div>

    <DeleteUserModal
      v-if="showDeleteModal"
      :user="selectedUser"
      @close="showDeleteModal = false"
      @confirm-delete="confirmDelete"
    />

    <EditUserModal
      v-if="showEditUserModal"
      :user="selectedUserToEdit"
      @close="showEditUserModal = false"
      @user-updated="fetchUsers"
    />

  </div>
</template>

<script>
import CreateUserModal from "@/components/users/CreateUserModal.vue";
import DeleteUserModal from "@/components/users/DeleteUserModal.vue";
import EditUserModal from "@/components/users/EditUserModal.vue";
import ManageLinesModal from "@/components/users/ManageLinesModal.vue"

export default {
  components: { CreateUserModal, DeleteUserModal, EditUserModal, ManageLinesModal },
  data() {
    return {
      users: [], 
      roles: ["admin", "agent"], 
      selectedRole: "", 
      currentPage: 1, 
      itemsPerPage: 10, 
      showCreateUserModal: false,
      showDeleteModal: false,
      showEditUserModal: false,
      selectedUserToEdit: null,
      selectedUser: null,
      currentUserId: null,
      showManageLinesModal: false,
      selectedUserForLines: null,
    };
  },
  computed: {
   filteredUsers() {
      let users = this.users;

      if (this.selectedRole) {
        users = users.filter(user => user.role === this.selectedRole);
      }

      return users.sort((a, b) => (a.id === this.currentUserId ? -1 : b.id === this.currentUserId ? 1 : 0));
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredUsers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.itemsPerPage);
    },
  },
  methods: {
    getCurrentUserId() {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;

        const payload = JSON.parse(atob(token.split(".")[1]));
        this.currentUserId = parseInt(payload.sub, 10);
      } catch (error) {
        console.error("Error al obtener el ID del usuario autenticado:", error);
      }
    },
    async fetchUsers() {
      try {
        const response = await this.$axios.get("/users"); 
        if (response.data && Array.isArray(response.data)) {
          this.users = response.data; 
        } else if (response.data && response.data.data) {
          this.users = response.data.data;
        } else {
          console.error("Formato de respuesta inesperado:", response.data);
        }
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },
    applyFilters() {
      this.currentPage = 1;
    },
    changePage(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.currentPage = newPage;
      }
    },
    editUser(user) {
      this.selectedUserToEdit = user;
      this.showEditUserModal = true;
    },
    openDeleteModal(user) {
      
      if (!user || !user.id) {
        console.error("Error: Usuario no válido.", user);
        return;
      }
      
      this.selectedUser = user;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      if (!this.selectedUser || !this.selectedUser.id) {
        console.error("Error: Usuario no seleccionado o ID no válido.");
        return;
      }

      try {
        await this.$axios.delete(`/users/${this.selectedUser.id}`);
        this.fetchUsers(); 
        this.showDeleteModal = false;
        this.selectedUser = null; 
      } catch (error) {
        console.error("Error al eliminar usuario:", error);
      }
    },
    openManageLinesModal(user) {
      this.selectedUserForLines = user;
      this.showManageLinesModal = true;
    },
  },
  mounted() {
    this.fetchUsers(); 
    this.getCurrentUserId();
  },
};
</script>

<style scoped>
.user-table-container {
  width: 90%;
  margin-top: 20px;
  margin-left: 25px;
}

.users-container {
  padding: 20px;
}

.username-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.current-user-tag {
  color: #28a745;
  font-size: 12px;
  font-weight: bold;
  background: rgba(40, 167, 69, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}

.icon-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-contact-btn {
  background-color: #007bff;
  color: white;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  width: 50px;
}

.add-contact-btn:hover {
  background-color: #0056b3;
}

.icon-add {
  font-size: 24px;
}

.filters-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.filter-group {
  position: relative;
  display: flex;
  align-items: center;
  width: 250px;
}

.select-icon {
  position: absolute;
  left: 10px;
  font-size: 18px;
  color: #007bff;
  pointer-events: none;
}

.filter-group select {
  width: 100%;
  padding: 10px 15px 10px 35px; 
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #f8f9fa;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-group select:hover {
  border-color: #007bff;
}

.filter-group select:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 91, 187, 0.3);
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-table th,
.user-table td {
  padding: 12px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #e0e0e0;
}

.no-data {
  text-align: center;
  font-style: italic;
  color: #6c757d;
}

.connection-status {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
}

.connection-status.online {
  color: #28a745;
}

.connection-status.offline {
  color: #dc3545;
}

.connection-status::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: currentColor;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
}

.icon-button.edit:hover {
  color: #007bff;
}

.icon-button.delete:hover {
  color: #dc3545;
}

.icon-button.lines:hover {
  color:#0056b3
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.pagination-button ion-icon {
  font-size: 18px;
}

</style>
