<template>
  <div class="minimal-overlay" @click.self="$emit('close')">
    <div class="minimal-modal">
      <div class="minimal-header">
        <h2>Agregar Contacto</h2>
        <button class="close-btn" @click="$emit('close')">
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>

      <div class="minimal-body">
        <form @submit.prevent="handleSubmit">
          <div class="input-row">
            <div class="form-group">
              <label for="name">Nombre</label>
              <input
                id="name"
                v-model.trim="formData.name"
                type="text"
                placeholder="Ingresa el nombre"
                required
              />
              <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
            </div>

            <div class="form-group">
              <label for="last_name">Apellido</label>
              <input
                id="last_name"
                v-model.trim="formData.last_name"
                type="text"
                placeholder="Ingresa el apellido"
                required
              />
              <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
            </div>
          </div>

          <div class="form-group phone-minimal">
            <div class="country-select">
              <label for="prefix" class="sr-only">País</label>
              <select id="prefix" v-model="formData.prefix" required>
                <option disabled value="">Selecciona un país</option>
                <option 
                  v-for="code in countryCodes" 
                  :key="code.id" 
                  :value="code.value"
                >
                  {{ code.emoji }} {{ code.name }}
                </option>
              </select>
              <span v-if="errors.prefix" class="error-message">{{ errors.prefix }}</span>
            </div>
            <input
              v-model.trim="formData.phone"
              type="tel"
              placeholder="Número de teléfono *"
              required
              pattern="^[0-9]{7,15}$"
              @input="formData.phone = formData.phone.replace(/\D/g, '')"
            />
            <span v-if="errors.phone" class="error-message">{{ errors.phone }}</span>
          </div>

          <div class="form-group">
            <label for="office">Oficina</label>
            <input
              id="office"
              v-model.trim="formData.office"
              type="text"
              placeholder="Ingresa la oficina"
              required
            />
            <span v-if="errors.office" class="error-message">{{ errors.office }}</span>
          </div>

          <div class="form-group">
            <label for="line">Línea de WhatsApp</label>
            <select id="line" v-model="formData.line" required>
              <option value="" disabled>Selecciona una línea</option>
              <option v-for="line in lines" :key="line.id" :value="line.id">
                {{ line.name }}
              </option>
            </select>
            <span v-if="errors.line" class="error-message">{{ errors.line }}</span>
          </div>

          <div class="minimal-footer">
            <button type="submit" class="save-btn">
              Agregar Contacto
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      default: null,
    },
    lines: {
      type: Array,
      required: true,
    },
    countryCodes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        last_name: '',
        office: '',
        prefix: '52', 
        phone: '',
        line: '',
      },
      errors: {},
    };
  },
  methods: {
    handleSubmit() {
      this.errors = {};
      if (!this.formData.name.trim()) {
        this.errors.name = 'El nombre es obligatorio';
      }
      if (!this.formData.last_name.trim()) {
        this.errors.last_name = 'El apellido es obligatorio';
      }
      if (!this.formData.office.trim()) {
        this.errors.office = 'La oficina es obligatoria';
      }
      if (!this.formData.prefix) {
        this.errors.prefix = 'Selecciona un país';
      }
      if (!/^[0-9]{7,15}$/.test(this.formData.phone)) {
        this.errors.phone = 'Ingresa un número de teléfono válido (7-15 dígitos)';
      }
      if (!this.formData.line) {
        this.errors.line = 'Selecciona una línea de WhatsApp';
      }
      if (Object.keys(this.errors).length === 0) {
        const completePhone = `${this.formData.prefix}${this.formData.phone}`;
        const contactData = {
          name: this.formData.name,
          last_name: this.formData.last_name,
          office: this.formData.office,
          phone: completePhone,
          line: this.formData.line,
        };
        this.$emit('save', contactData);
      }
    },
  },
};
</script>

<style scoped>
.minimal-overlay {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.minimal-modal {
  background-color: #fff;
  border-radius: 8px;
  width: 420px;
  max-width: 95%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: fadeInUp 0.3s ease forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(6px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.minimal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e90ff;
  padding: 10px 20px;
}

.minimal-header h2 {
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 29px;
  cursor: pointer;
}

.close-btn:hover {
  scale: 1.2;
}

.minimal-body {
  padding: 20px;
  background-color: #f9fafb;
}

.minimal-footer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.save-btn {
  background-color: #1e90ff;
  color: #fff;
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.save-btn:hover {
  background-color: #1677cc;
  box-shadow: 0 2px 6px rgba(22, 119, 204, 0.3);
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #555;
  font-size: 14px;
}

.input-row {
  display: flex;
  gap: 15px;
}

.form-group {
  flex: 1;
}

input,
select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  outline: none;
  background-color: #fff;
}

input:focus,
select:focus {
  border-color: #1e90ff;
  box-shadow: 0 0 0 3px rgba(30, 144, 255, 0.2);
}

input,
select {
  width: 95%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  outline: none;
  background-color: #fff;
}

input::placeholder {
  color: #999;
}

input:focus,
select:focus {
  border-color: #1e90ff;
  box-shadow: 0 0 0 3px rgba(30, 144, 255, 0.2);
}

.phone-minimal {
  display: flex;
  gap: 8px;
}

.country-select {
  min-width: 100px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.error-message {
  color: #d9534f;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

</style>


