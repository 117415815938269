<template>
  <div class="contact-table-container">
    <table class="contact-table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Número</th>
          <th>Oficina</th>
          <th>Línea</th>
          <th>Etiquetas</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contact in contacts" :key="contact.id">
          <td>{{ contact.name || 'Sin nombre' }}</td>
          <td>{{ contact.last_name }}</td>
          <td>{{ contact.phone_number }}</td>
          <td>{{ contact.office }}</td>
          <td>{{ contact.line_name ? contact.line_name : 'Desconocido' }}</td>
          <td>
            <span
              v-if="hasValidTag(contact)"
              class="tag"
              :style="{ backgroundColor: contact.tags[0].color }"
            >
              {{ contact.tags[0].name || 'Sin etiqueta' }}
            </span>
          </td>
          <td class="action-buttons">
            <button 
              v-if="userRole !== 'agent'"
              @click="$emit('edit-contact', contact)" 
              class="icon-button edit"
            >
              <ion-icon name="create-outline"></ion-icon>
            </button>
            <button
              v-if="userRole !== 'agent'"
              @click="$emit('delete-contact', contact.id)"
              class="icon-button delete"
            >
              <ion-icon name="trash-outline"></ion-icon>
            </button>
            <button 
              @click="goToChat(contact.phone_number)" 
              class="icon-button chat"
            >
              <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    userRole: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasValidTag(contact) {
      return contact.tags && contact.tags.length > 0 && contact.tags[0].color;
    },
    goToChat(phoneNumber) {
      const lineId = this.$route.params.lineId || this.getUserLineId(); 
      if (!lineId) {
        console.error("Error: lineId no definido");
        return;
      }

      this.$router.push({
        name: "ChatPage",
        params: { lineId }, 
        query: { phone: phoneNumber },
      });
    },
    getUserLineId() {
      return this.contacts.length > 0 ? this.contacts[0].line_id : null;
    },
  },
};
</script>

<style scoped>
.contact-table-container {
  width: 100%;
}

.contact-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-table th, 
.contact-table td {
  padding: 12px;
  text-align: center; 
  vertical-align: middle; 
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  text-overflow: ellipsis; /* Agrega "..." si el texto es muy largo */
  white-space: nowrap;
}

.contact-table th {
  background: #f8f9fa;
  font-weight: 600;
  text-align: center;
}

.action-buttons {
  display: flex;
  justify-content: center; 
  align-items: center; 
  gap: 10px;
}

.icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  color: #007bff;
}

.icon-button.delete:hover {
  color: #dc3545;
}

.icon-button.chat:hover {
  color: #28a745;
}

.tag {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border-radius: 12px; 
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); 
  margin: auto;
  margin-top: -6px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .contact-table th, 
  .contact-table td {
    padding: 10px; 
    font-size: 14px; 
  }

  .action-buttons {
    gap: 5px;
  }

  .icon-button {
    font-size: 18px; 
  }
}

@media (max-width: 480px) {
  .contact-table th, 
  .contact-table td {
    font-size: 12px; 
    padding: 8px; 
  }

  .action-buttons {
    flex-direction: column; 
    gap: 3px;
  }

  .icon-button {
    font-size: 16px; 
  }

  .tag {
    font-size: 12px; 
    padding: 4px 8px; 
  }
}

</style>
