import Vue from 'vue';
import App from './App.vue';
import router from './router'; 
import axios from 'axios';

Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.prototype.$axios = axios; 

axios.defaults.baseURL = 'https://metricwsback.cwp.mx/api'; 
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
