<template>
  <div class="metrics-container">
    <!-- Encabezado -->
    <div class="header">
      <div class="header-left">
        <span class="header-subtitle">MÉTRICAS</span>
        <div class="header-main">
          <ion-icon name="stats-chart-outline" class="header-icon"></ion-icon>
          <h1 class="header-title">Mensajes</h1>
        </div>
      </div>

      <div class="header-right">
        <button class="filter-button" @click="openModal('date')">
          <ion-icon name="calendar-outline"></ion-icon>
        </button>
        <button class="filter-button" @click="openModal('hour')">
          <ion-icon name="time-outline"></ion-icon>
        </button>
        <div class="dropdown-container">
          <button class="filter-button" @click="toggleLineDropdown">
            <ion-icon name="options-outline"></ion-icon>
            {{ selectedLine ? getLineName(selectedLine) : 'General' }}
          </button>
          <ul v-if="showLineDropdown" class="line-dropdown">
            <li
              v-for="line in lines"
              :key="line.id"
              @click="applyLineFilter(line.id)"
            >
              {{ line.line_name }}
            </li>
            <li @click="applyLineFilter(null)">General</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Modal reutilizable -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h2>{{ modalType === 'date' ? 'Seleccionar rango de fechas' : 'Seleccionar rango de horas' }}</h2>

        <div class="modal-body">
          <div v-if="modalType === 'date'" class="modal-inputs">
            <div>
              <label for="start-date">Fecha de inicio:</label>
              <input type="date" id="start-date" v-model="dateFilters.startDate" />
            </div>
            <div>
              <label for="end-date">Fecha de fin:</label>
              <input type="date" id="end-date" v-model="dateFilters.endDate" />
            </div>
          </div>
          <div v-if="modalType === 'hour'" class="modal-inputs">
            <div>
              <label for="start-hour">Hora de inicio:</label>
              <input type="time" id="start-hour" v-model="hourFilters.startHour" />
            </div>
            <div>
              <label for="end-hour">Hora de fin:</label>
              <input type="time" id="end-hour" v-model="hourFilters.endHour" />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            class="btn-confirm"
            @click="applyFilters"
            :disabled="modalType === 'date' ? !isDateValid : !isHourValid"
          >
            Confirmar
          </button>
          <button class="btn-cancel" @click="closeModal">Cerrar</button>
        </div>
        <p v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </p>
      </div>
    </div>

    <!-- Botón para abrir el modal de filtros aplicados -->
    <div v-if="hasActiveFilters" class="filters-dropdown-container">
      <button class="filters-toggle" @click="toggleFiltersDropdown">
        <ion-icon name="filter-outline"></ion-icon> Filtros Aplicados
      </button>

      <!-- Dropdown de Filtros Aplicados -->
      <div v-if="showFiltersDropdown" class="filters-dropdown">
        <p v-if="filters.startDate"><strong>Fecha Inicio:</strong> {{ filters.startDate }}</p>
        <p v-if="filters.endDate"><strong>Fecha Fin:</strong> {{ filters.endDate }}</p>
        <p v-if="filters.startHour"><strong>Hora Inicio:</strong> {{ filters.startHour }}</p>
        <p v-if="filters.endHour"><strong>Hora Fin:</strong> {{ filters.endHour }}</p>
        <p v-if="filters.line"><strong>Línea:</strong> {{ getLineName(filters.line) }}</p>
        <p v-if="filters.hour"><strong>Hora Exacta:</strong> {{ filters.hour }}:00</p>

        <button class="btn-clear" @click="clearFilters">Limpiar Filtros</button>
      </div>
    </div>

    <!-- Métricas Widgets -->
    <div class="metrics-grid">
      <!-- Total de Mensajes -->
      <div class="widget">
        <span class="widget-title">Total de Mensajes</span>
        <ion-icon name="chatbubble-ellipses-outline" class="widget-icon"></ion-icon>
        <p class="widget-value">{{ metrics.total_messages }}</p>
      </div>

      <!-- Porcentaje de Respuesta -->
      <div class="widget">
        <span class="widget-title">Porcentaje de Respuesta</span>
        <ion-icon name="checkmark-done-outline" class="widget-icon"></ion-icon>
        <p class="widget-value">{{ metrics.response_percentage }}%</p>
      </div>

      <!-- Tiempo de Respuesta Promedio -->
      <div class="widget">
        <span class="widget-title">Tiempo de Respuesta Promedio</span>
        <ion-icon name="time-outline" class="widget-icon"></ion-icon>
        <p class="widget-value">{{ metrics.average_response_time }} minutos</p>
      </div>

      <!-- Estado de Mensajes -->
      <div class="widget">
        <span class="widget-title">Estado de Mensajes</span>
        <ion-icon name="stats-chart-outline" class="widget-icon"></ion-icon>
        <ul class="widget-list">
          <li v-for="status in metrics.message_status" :key="status.status">
            <span class="list-label">{{ status.status }}:</span>
            <span class="list-value">{{ status.count }}</span>
          </li>
        </ul>
      </div>

      <!-- Tipos de Mensajes -->
      <div class="widget">
        <span class="widget-title">Tipos de Mensajes</span>
        <ion-icon name="document-text-outline" class="widget-icon"></ion-icon>
        <ul class="widget-list">
          <li v-for="type in metrics.message_types" :key="type.type">
            <span class="list-label">{{ type.type }}:</span>
            <span class="list-value">{{ type.count }}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Gráficos -->
    <div class="charts">
      <!-- Mensajes por día -->
      <div class="chart-card">
        <h3 class="chart-title">Mensajes por Día</h3>
        <canvas id="messagesByDay"></canvas>
      </div>

      <!-- Horas activas -->
      <div class="chart-card">
        <h3 class="chart-title">Horas Activas</h3>
        <canvas id="activeHours"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Chart from "chart.js/auto";

export default {
  name: "MessagesMetrics",
  data() {
    return {
      showFiltersDropdown: false,
      showModal: false,
      modalType: "", 
      errorMessage: "",
      dateFilters: {
        startDate: "",
        endDate: "",
      },
      hourFilters: {
        startHour: "",
        endHour: "",
      },
      filters: {
        startDate: "",
        endDate: "",
        startHour: "",
        endHour: "",
        line: null,
      },
      // Fitro Lineas
      showLineDropdown: false,
      selectedLine: null,
      // Métricas
      metrics: {
        total_messages: 0,
        response_percentage: 0,
        average_response_time: 0,
        message_status: [],
        message_types: [],
        messages_by_day: [],
        active_hours: [],
      },
      // Lineas
      lines: [],
      // Gráficos
      charts: {
        messagesByDay: null,
        activeHours: null,
      },
    };
  },
  computed: {
    isDateValid() {
      return this.dateFilters.startDate && this.dateFilters.endDate;
    },
    isHourValid() {
      return this.hourFilters.startHour && this.hourFilters.endHour;
    },
    hasActiveFilters() {
      return (
        this.filters.startDate ||
        this.filters.endDate ||
        this.filters.startHour ||
        this.filters.endHour ||
        this.filters.line ||
        this.filters.hour
      );
    },
  },
  methods: {
    openModal(type) {
      this.modalType = type;
      this.showModal = true;
      this.errorMessage = "";
      this.$nextTick(() => {
        const overlay = document.querySelector(".modal-overlay");
        if (overlay) overlay.classList.add("show");
      });
    },
    closeModal() {
      const overlay = document.querySelector(".modal-overlay");
      if (overlay) {
        overlay.classList.remove("show");
        setTimeout(() => {
          this.showModal = false;
        }, 300);
      }
    },
    formatDate(date) {
      if (!date) return "";
        const parsedDate = new Date(date);
        return parsedDate.toISOString().split("T")[0];
    },
    formatHourTo24(hour) {
      if (!hour) return "";
      const [time, period] = hour.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (period === "PM" && hours !== 12) hours += 12;
      if (period === "AM" && hours === 12) hours = 0;

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    },
    validateFilters() {
      this.errorMessage = "";

      const formattedStartDate = this.formatDate(this.dateFilters.startDate);
      const formattedEndDate = this.formatDate(this.dateFilters.endDate);

      if (!formattedStartDate || !formattedEndDate) {
        this.showError("Debe seleccionar ambas fechas.");
        return false;
      }

      if (formattedStartDate > formattedEndDate) {
        this.showError("La fecha de inicio no puede ser mayor que la fecha de fin.");
        return false;
      }

      if ((this.dateFilters.startHour && !this.dateFilters.endHour) || (!this.dateFilters.startHour && this.dateFilters.endHour)) {
        this.showError("Selecciona ambas horas.");
        return false;
      }

      if (formattedStartDate === formattedEndDate && this.dateFilters.startHour > this.dateFilters.endHour) {
        this.showError("La hora de inicio no puede ser mayor que la hora de fin en el mismo día.");
        return false;
      }

      return true;
    },
    showError(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 3000);
    },
    applyFilters() {
      if (this.modalType === "date") {
        if (!this.isDateValid) {  
          this.showError("Debe seleccionar ambas fechas.");
          return;
        }
        this.filters.startDate = this.dateFilters.startDate;
        this.filters.endDate = this.dateFilters.endDate;
      } else if (this.modalType === "hour") {
        if (!this.isHourValid) {  
          this.showError("Debe seleccionar ambas horas.");
          return;
        }
        this.filters.startHour = this.formatHourTo24(this.hourFilters.startHour);
        this.filters.endHour = this.formatHourTo24(this.hourFilters.endHour);
      }

      this.closeModal();
      this.fetchMetrics();
    },
    closeFiltersModal() {
      this.showFiltersModal = false;
    },
    async fetchMetrics() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token no disponible");
          return;
        }

        const { startDate, endDate, line, startHour, endHour } = this.filters;
        const url = line ? `/metrics/messages/line/${line}` : "/metrics/messages";

        const params = {};
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;
        if (startHour) params.start_hour = startHour;
        if (endHour) params.end_hour = endHour;

        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
          params: params,
        });

        this.metrics = response.data;
        this.updateCharts();
      } catch (error) {
        console.error("Error fetching metrics:", error.response || error);
      }
    },
    async fetchLines() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token no disponible");
          return;
        }

        const response = await axios.get("/lines", {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.lines = response.data;
      } catch (error) {
        console.error("Error fetching lines:", error.response || error);
      }
    },
    updateCharts() {
      if (this.charts.messagesByDay) this.charts.messagesByDay.destroy();
      if (this.charts.activeHours) this.charts.activeHours.destroy();

      // Mensajes por día
      const messagesByDayCtx = document.getElementById("messagesByDay").getContext("2d");
      this.charts.messagesByDay = new Chart(messagesByDayCtx, {
        type: "bar",
        data: {
          labels: this.metrics.messages_by_day.map((d) => d.day),
          datasets: [
            {
              label: "Mensajes por Día",
              data: this.metrics.messages_by_day.map((d) => d.count),
              backgroundColor: "rgba(66, 165, 245, 0.5)",
              borderColor: "#42A5F5",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      // Horas activas
      const activeHoursCtx = document.getElementById("activeHours").getContext("2d");
      this.charts.activeHours = new Chart(activeHoursCtx, {
        type: "line",
        data: {
          labels: this.metrics.active_hours.map((h) => `${h.hour}:00`),
          datasets: [
            {
              label: "Horas Activas",
              data: this.metrics.active_hours.map((h) => h.count),
              backgroundColor: "rgba(66, 165, 245, 0.5)",
              borderColor: "#42A5F5",
              borderWidth: 2,
              fill: true,
              tension: 0.4, 
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    toggleFiltersDropdown() {
      this.showFiltersDropdown = !this.showFiltersDropdown;

      if (this.showFiltersDropdown) {
        this.$nextTick(() => {
          const dropdown = document.querySelector('.filters-dropdown');
          if (dropdown) {
            dropdown.classList.add('show');
          }
        });
      } else {
        const dropdown = document.querySelector('.filters-dropdown');
        if (dropdown) {
          dropdown.classList.remove('show');
        }
      }
    },
    toggleLineDropdown() {
      this.showLineDropdown = !this.showLineDropdown;
    },
    applyLineFilter(lineId) {
      this.selectedLine = lineId; 
      this.filters.line = lineId; 
      this.showLineDropdown = false; 
      this.fetchMetrics(); 
    },
    getLineName(lineId) {
      const line = this.lines.find((line) => line.id === lineId);
      return line ? line.line_name : "Todas";
    },
    clearFilters() {
      this.filters = {
        startDate: "",
        endDate: "",
        startHour: "",
        endHour: "",
        line: null, 
        hour: "",
      };

      this.dateFilters = {
        startDate: "",
        endDate: "",
      };

      this.hourFilters = {
        startHour: "",
        endHour: "",
      };

      this.selectedLine = null; 
      this.showFiltersDropdown = false; 
      this.fetchMetrics();
    },
  },
  mounted() {
    this.fetchMetrics();
    this.fetchLines();
  },
};
</script>

<style scoped>
html, body {
  overflow: auto; 
}

.metrics-container {
  height: calc(100vh - 100px);
  padding: 20px;
  background: #f9fafb;
  max-height: 100vh;
  overflow-y: auto; 
}

.header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 40px;
}

.header-subtitle {
  font-size: 12px;
  color: #999;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}

.header-main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  font-size: 25px;
  color: #007bff;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.header-right {
  display: flex;
  align-items: center;
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.filter-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; 
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  width: 480px;
  max-width: 90%;
  animation: scaleIn 0.4s ease;
  border: 1px solid #e3e3e3;
  text-align: center;
}

.modal-content h2 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-inputs {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.modal-inputs div {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.modal-inputs label {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 8px;
}

.modal-inputs input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  transition: border-color 0.3s ease;
}

.modal-inputs input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  outline: none;
}

.error-message {
  margin-top: 15px;
  padding: 10px;
  background: #f5f5f5;
  color: #be1818; 
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.5s ease-in-out 2.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
}

.btn-confirm {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-confirm:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-cancel:hover {
  background-color: #495057;
  transform: scale(1.05);
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.filters-dropdown-container {
  position: relative;
  margin-top: -20px;
  margin-bottom: 25px;
  display: flex;
  justify-content: left;
}

.filters-toggle {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background 0.3s ease-in-out;
}

.filters-toggle:hover {
  background: #0056b3;
}

.filters-dropdown {
  position: absolute;
  top: 50px; 
  background: white;
  width: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: left;
  z-index: 1000;
  opacity: 0; 
  transform: translateY(-20px); 
  transition: all 0.3s ease;
  pointer-events: none;
}

.filters-dropdown.show {
  opacity: 1; 
  transform: translateY(0); 
  pointer-events: auto; 
}

.filters-dropdown p {
  font-size: 14px;
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  background: #f5f5f5;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn-clear {
  background: #dc3545;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.btn-clear:hover {
  background: #bd2130;
  transform: scale(1.05);
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.line-dropdown {
  position: absolute;
  top: 100%; 
  left: 0;
  top: 20px;
  z-index: 1000;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  width: 100%;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

.line-dropdown li {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  width: 100%; 
}

.line-dropdown li:hover {
  background-color: #f1f1f1;
}

.filter-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease-in-out;
}

.filter-button:hover {
  background-color: #0056b3;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); 
  gap: 15px;
  margin-bottom: 20px;
}

.widget {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.widget-title {
  font-size: 12px;
  color: #6c757d;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.widget-icon {
  font-size: 24px;
  color: #007bff;
}

.widget-value {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.widget-list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: #555;
}

.widget-list li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
}

.widget-list .list-label {
  font-weight: bold;
}

.widget-list .list-value {
  color: #333;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.chart-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 1000px) {
  .charts {
    grid-template-columns: 1fr; 
  }
}
</style>
