<template>
  <div class="notification-container" ref="notificationDropdown">
    <button class="notification-btn" @click="toggleDropdown">
      <ion-icon name="notifications-outline"></ion-icon>
      <span v-if="unreadCount" class="notification-badge">{{ unreadCount }}</span>
    </button>

    <transition name="fade">
      <div v-if="isOpen" class="notification-dropdown">
        <div class="notification-header">
          <h3>Notificaciones</h3>
          <button v-if="notifications.length" class="clear-btn" @click="clearAll">Limpiar</button>
        </div>

        <p v-if="notifications.length === 0" class="empty-message">No tienes notificaciones</p>

        <ul v-else class="notification-list">
          <li v-for="notification in notifications" :key="notification.id" class="notification-item">
            <div class="notification-content" @click="goToChat(notification.contact_id, notification.line_id)">
              
              <ion-icon 
                :name="notification.type === 'escalated' ? 'alert-circle-outline' : 'chatbubble-outline'"
                :class="notification.type === 'escalated' ? 'escalated-icon' : 'message-icon'">
              </ion-icon>

              <div class="notification-text">
                <div class="notification-header-text">
                  <strong class="contact-name">{{ notification.contact_name || 'Desconocido' }}</strong>
                  <span class="phone-number">| {{ notification.phone_number || 'Sin número' }}</span>
                </div>
                <small class="notification-line">{{ notification.line_name || 'Sin línea' }}</small>
                <small class="notification-time">{{ formatDate(notification.created_at) }}</small>
              </div>
            </div>

            <div class="notification-actions">
              <ion-icon 
                v-if="!notification.is_seen" 
                name="checkmark-outline" 
                class="mark-read"
                @click="markAsRead(notification.id)">
              </ion-icon>
              <ion-icon 
                name="trash-outline" 
                class="delete" 
                @click="deleteNotification(notification.id)">
              </ion-icon>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isOpen: false,
      notifications: [],
      pollingInterval: null,
    };
  },
  computed: {
    unreadCount() {
      return this.notifications.filter((n) => !n.is_seen).length;
    },
  },
  methods: {
    async fetchNotifications() {
      try {
        const response = await axios.get("/notifications");
        this.notifications = response.data.notifications;
      } catch (error) {
        console.error("Error al obtener notificaciones:", error);
      }
    },
    async markAsRead(id) {
      try {
        await axios.post(`/notifications/mark-as-seen/${id}`);
        const notif = this.notifications.find((n) => n.id === id);
        if (notif) notif.is_seen = true;
      } catch (error) {
        console.error("Error al marcar como leída:", error);
      }
    },
    async deleteNotification(id) {
      try {
        await axios.delete(`/notifications/${id}`);
        this.notifications = this.notifications.filter((n) => n.id !== id);
      } catch (error) {
        console.error("Error al eliminar notificación:", error);
      }
    },
    async clearAll() {
      try {
        await axios.delete("/notifications/all"); 
        this.notifications = [];
      } catch (error) {
        console.error("Error al limpiar notificaciones:", error);
      }
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.fetchNotifications();
        this.markAllAsRead();
      }
    },
    async goToChat(contactId, lineId) {
      if (!contactId || !lineId) {
        console.error("Error: contactId o lineId no definido");
        return;
      }

      try {
        const response = await axios.get(`/contacts/${contactId}`);
        const phoneNumber = response.data.phone_number;

        if (!phoneNumber) {
          console.error("Error: No se encontró el número de teléfono");
          return;
        }

        const newPath = `/dashboard/chat/${lineId}?phone=${phoneNumber}`;
        const currentPath = this.$route.fullPath;

        if (newPath === currentPath) {
          console.log("🚫 Navegación ignorada: ya estás en la misma conversación.");
          return;
        }

        this.$router.push({ path: newPath });

        this.isOpen = false; 
      } catch (error) {
        console.error("Error al obtener el número de teléfono:", error);
      }
    },
    getUserLineId() {
      return this.notifications.length > 0 ? this.notifications[0].line_id : null;
    },
    formatDate(dateString) {
      if (!dateString) return "Fecha desconocida";
      return new Date(dateString).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    async markAllAsRead() {
      try {
        await axios.post(`/notifications/mark-as-seen/all`);
        
        if (Array.isArray(this.notifications)) {
          this.notifications = this.notifications.map(n => ({
            ...n,
            is_seen: true
          }));
        }
      } catch (error) {
        console.error("Error al marcar todas como leídas:", error);
      }
    },
    startPolling() {
      this.pollingInterval = setInterval(() => {
        const token = localStorage.getItem("token");
        if (!token) {
          this.stopPolling();
          return;
        }

        this.fetchNotifications();
      }, 15000);
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },
  },
  watch: {
    "$route"(to) {
      if (to.path === "/login") {
        this.stopPolling();
      }
    }
  },
  mounted() {
    this.fetchNotifications();
    this.startPolling();
    document.addEventListener("click", (event) => {
      if (this.isOpen && this.$refs.notificationDropdown?.contains && !this.$refs.notificationDropdown.contains(event.target)) {
        this.isOpen = false;
      }
    });
  },
  beforeUnmount() {
    this.stopPolling();
  }
};
</script>

<style scoped>
.notification-container {
  position: relative;
}

.notification-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  position: relative;
  color: #fff;
  transition: color 0.2s ease;
}

.notification-btn:hover {
  color: #f0f0f0;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff3b30;
  color: white;
  font-size: 11px;
  font-weight: bold;
  padding: 3px 6px;
  border-radius: 50%;
}

.empty-message {
  color: #333;
}

.notification-dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  width: 350px;
  max-height: 400px; 
  overflow-y: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  padding: 12px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e5e5e5;
  padding-bottom: 6px;
}

.notification-header h3 {
  font-size: 16px;
  font-weight: bold;
  color: #222;
}

.clear-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s;
}

.clear-btn:hover {
  color: #0056b3;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  transition: background 0.2s ease-in-out;
  border-radius: 6px;
  min-height: 50px; 
}

.notification-item:hover {
  background: #f8f9fa;
  cursor: pointer;
}

.notification-content {
  display: flex;
  align-items: center;
  gap: 16px; 
  flex-grow: 1;
}

.notification-content ion-icon {
  font-size: 24px; 
  flex-shrink: 0;
}

.escalated-icon {
  color: #ff3b30; 
}

.message-icon {
  color: #007bff;
}

.notification-text {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
  flex-grow: 1;
}

.notification-header-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #222;
  gap: 4px; 
  flex-wrap: nowrap;
}

.phone-number {
  font-size: 13px;
  font-weight: bold;
  color: #555;
}

.contact-name {
  font-weight: bold;
  margin-left: 20px;
}

.notification-line {
  font-size: 12px;
  color: #666;
  margin-top: 1px;
}

.notification-time {
  font-size: 11px;
  color: #888;
  margin-top: 1px;
}

.notification-actions {
  display: flex;
  gap: 8px; 
}

.notification-actions ion-icon {
  font-size: 20px; 
  cursor: pointer;
  transition: color 0.2s;
}

.notification-actions .mark-read:hover {
  color: #28a745;
}

.notification-actions .delete {
  margin-left: 10px;
}

.notification-actions .delete:hover {
  color: #dc3545;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Estiliza el scrollbar para que sea más elegante */
.notification-dropdown::-webkit-scrollbar {
  width: 6px;
}

.notification-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

.notification-dropdown::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 8px;
}

.notification-dropdown::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}
</style>
