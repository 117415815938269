<template>
  <div class="modal-overlay">
    <div class="modal-container">
      <h2>Administrar Notificaciones De <span class="username">{{ user.username }}</span></h2>

      <div class="lines-list">
        <label 
          v-for="line in availableLines" 
          :key="line.id" 
          class="line-item"
          :class="{ 'not-assigned': !userLines.includes(line.id) }"
        >
          <input 
            type="checkbox" 
            :checked="userLines.includes(line.id)" 
            @change="toggleLine(line.id)"
          />
          <span class="checkmark"></span>
          {{ line.line_name }}
        </label>
      </div>

      <div class="modal-actions">
        <button class="btn-save" @click="saveChanges">
          <ion-icon name="save-outline"></ion-icon> Guardar
        </button>
        <button class="btn-close" @click="$emit('close')">
          <ion-icon name="close-outline"></ion-icon> Cerrar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    user: Object
  },
  data() {
    return {
      availableLines: [],
      userLines: []
    };
  },
  async mounted() {
    await this.fetchAvailableLines();
    await this.fetchUserLines();
  },
  methods: {
    async fetchAvailableLines() {
      try {
        const response = await axios.get('/lines');
        this.availableLines = response.data;
      } catch (error) {
        console.error("Error al obtener líneas:", error);
      }
    },
    async fetchUserLines() {
      try {
        const response = await axios.get(`/user-lines/${this.user.id}`);
        this.userLines = response.data.map(line => line.line_id); 
      } catch (error) {
        console.error("Error al obtener líneas asignadas:", error);
      }
    },
    toggleLine(lineId) {
      if (this.userLines.includes(lineId)) {
        this.userLines = this.userLines.filter(id => id !== lineId);
      } else {
        this.userLines.push(lineId);
      }
    },
    async saveChanges() {
      try {
        const originalUserLines = await axios.get(`/user-lines/${this.user.id}`);
        const originalLines = originalUserLines.data.map(line => line.line_id);

        const linesToAdd = this.userLines.filter(id => !originalLines.includes(id));

        const linesToRemove = originalLines.filter(id => !this.userLines.includes(id));

        for (const lineId of linesToAdd) {
          await axios.post(`/user-lines`, {
            user_id: this.user.id,
            line_id: lineId
          });
        }

        for (const lineId of linesToRemove) {
          await axios.delete(`/user-lines/${this.user.id}/${lineId}`);
        }

        this.$emit("lines-updated");
        this.$emit("close");
      } catch (error) {
        console.error("Error al actualizar líneas:", error);
      }
    },
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  background: #ffffff;
  padding: 24px;
  border-radius: 10px;
  width: 450px;
  max-width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.2s ease-in-out;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.username {
  color: #007bff;
}

.lines-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #f9f9f9;
  margin-bottom: 15px;
}

.line-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  position: relative;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.line-item:hover {
  background: rgba(0, 123, 255, 0.1);
}

.line-item.not-assigned {
  opacity: 0.7;
  color: #777;
}

.line-item input {
  display: none;
}

.line-item .checkmark {
  width: 18px;
  height: 18px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
}

.line-item input:checked + .checkmark {
  background: #007bff;
  border-color: #0056b3;
}

.line-item input:checked + .checkmark::after {
  content: "✔";
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-save,
.btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease-in-out, transform 0.15s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-save {
  background: #007bff;
  color: white;
  border: 1px solid transparent;
}

.btn-save:hover {
  background: #0056b3;
  border-color: #004494;
  transform: translateY(-1px);
}

.btn-save:active {
  background: #004494;
  border-color: #003a7c;
  transform: translateY(0);
}

.btn-close {
  background: #f8f9fa; 
  color: #495057;
  border: 1px solid #ced4da;
}

.btn-close:hover {
  background: #e9ecef;
  border-color: #adb5bd;
  transform: translateY(-1px);
}

.btn-close:active {
  background: #dee2e6;
  border-color: #adb5bd;
  transform: translateY(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 480px) {
  .modal-container {
    width: 90%;
    padding: 18px;
  }
}
</style>