<template>
  <div class="lines-table-container">
    <!-- Nuevo encabezado -->
    <div class="header">
      <div class="header-left">
        <span class="header-subtitle">LINEAS</span> 
        <div class="header-main">
          <ion-icon name="call-outline" class="header-icon"></ion-icon> 
          <h1 class="header-title">Líneas</h1> 
        </div>
      </div>
    </div>

    <table class="lines-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nombre de Línea</th>
          <th>Número de Teléfono</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="lines.length === 0">
          <td colspan="4" class="no-data">No hay líneas disponibles</td>
        </tr>

        <tr v-for="line in lines" :key="line.id">
          <td>{{ line.id }}</td>
          <td>{{ line.line_name }}</td>
          <td>{{ line.phone_number }}</td>
          <td>
            <span :class="['status-tag', line.status === 'active' ? 'active' : 'inactive']">
              {{ line.status === 'active' ? 'Activa' : 'Inactiva' }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lines: [], 
    };
  },
  methods: {
    async fetchLines() {
      try {
        const response = await this.$axios.get("/lines"); 
        if (response.data && Array.isArray(response.data)) {
          this.lines = response.data;
        } else {
          console.error("Formato de respuesta inesperado:", response.data);
        }
      } catch (error) {
        console.error("Error al obtener líneas:", error);
      }
    },
  },
  mounted() {
    this.fetchLines(); 
  },
};
</script>

<style scoped>
.lines-table-container {
  width: 90%;
  margin: 20px auto;
}

.header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 40px;
}

.header-subtitle {
  font-size: 12px;
  color: #999;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}

.header-main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  font-size: 25px;
  color: #007bff;
}

.header-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.lines-table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.lines-table th,
.lines-table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.no-data {
  text-align: center;
  font-style: italic;
  color: #6c757d;
}

.status-tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.status-tag.active {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.status-tag.inactive {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}
</style>
